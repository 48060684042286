<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <!-- <block-maitrise-langues title="" v-model="editableItem.maitriseLangues" :hideAlphabetisation="true" /> -->
        <div class="row">
          <h5>Effectifs permanents</h5>

          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanentHomme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanentHomme"
                  name="effectifPermanentHomme"
                  placeholder="Effectif permanent Homme"
                />
                <label for="effectifPermanentHomme">Homme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanentFemme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanentFemme"
                  name="effectifPermanentFemme"
                  placeholder="Effectif permanent femme"
                />
                <label for="effectifPermanentFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanent"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanent"
                  name="effectifPermanent"
                  placeholder="Effectif total"
                  disabled
                />
                <label for="effectifPermanent">Total</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <h5>Effectifs temporaires</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaireHomme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaireHomme"
                  name="effectifTemporaireHomme"
                  placeholder="effectif temporaire homme"
                />
                <label for="effectifTemporaireHomme">Homme </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaireFemme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaireFemme"
                  name="effectifTemporaireFemme"
                  placeholder="Effectif Temporaire femme"
                />
                <label for="effectifTemporaireFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaire"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaire"
                  name="effectifTemporaire"
                  placeholder="Effectif temporaire"
                  disabled
                />
                <label for="effectifTemporaire">Total</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <hr />
          <h5>Effectifs stagiaires</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaireHomme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaireHomme"
                  name="effectifStagiaireHomme"
                  placeholder="Homme"
                />
                <label for="effectifStagiaireHomme">Homme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaireFemme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaireFemme"
                  name="effectifStagiaireFemme"
                  placeholder="Femme"
                />
                <label for="effectifStagiaireFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaire"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaire"
                  name="effectifStagiaire"
                  placeholder="Effectif stagiaire"
                  readonly
                />
                <label for="effectifStagiaire">Total </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <h5>Effectif total</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalHomme"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotalHomme"
                  name="effectifTotalHomme"
                  placeholder="Effectif total homme"
                  disabled
                />
                <label for="effectifTotalHomme">Homme </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalFemme"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotalFemme"
                  name="effectifTotalFemme"
                  placeholder="Effectif total femme"
                  disabled
                />
                <label for="effectifTotalFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotal"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotal"
                  name="effectifTotal"
                  placeholder="Effectif total"
                  disabled
                />
                <label for="effectifTotalFemme">Total </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  props: ["value"],
  components: {
  },
  data() {
    return {
      hasNip: null,
      pieces: [],
      editableItem: { effectifPermanentFemme: 0, effectifPermanentHomme: 0 },
    };
  },
  computed: {
    effectifPermanent() {
      return parseInt(this.editableItem.effectifPermanentFemme || 0) +
                      parseInt(this.editableItem.effectifPermanentHomme || 0)
    },
    effectifTemporaire() {
      return parseInt(this.editableItem.effectifTemporaireHomme || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemme || 0)
    },
    effectifStagiaire() {
      return parseInt(this.editableItem.effectifStagiaireHomme || 0) +
                      parseInt(this.editableItem.effectifStagiaireFemme || 0)
    },
    effectifTotalHomme() {
      return parseInt(this.editableItem.effectifStagiaireHomme || 0) +
                      parseInt(this.editableItem.effectifTemporaireHomme || 0) +
                      parseInt(this.editableItem.effectifPermanentHomme || 0)                  
    },
    effectifTotalFemme() {
      return parseInt(this.editableItem.effectifStagiaireFemme || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemme || 0) +
                      parseInt(this.editableItem.effectifPermanentFemme || 0)        
    },
    effectifTotal() {
      return parseInt(this.editableItem.effectifStagiaireHomme || 0) +
                      parseInt(this.editableItem.effectifTemporaireHomme || 0) +
                      parseInt(this.editableItem.effectifPermanentHomme || 0) +

                      parseInt(this.editableItem.effectifStagiaireFemme || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemme || 0) +
                      parseInt(this.editableItem.effectifPermanentFemme || 0)  
                      
    }
  },
  created() {
    this.editableItem = { ...this.value };
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", { ...this.value, ...this.editableItem });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.calculerTotaux()
      this.$emit("input", { ...this.value, ...this.editableItem });
    },
    calculerTotaux() {
      this.editableItem = {
        ...this.editableItem,
        effectifPermanent: this.effectifPermanent,
        effectifTemporaire: this.effectifTemporaire,
        effectifStagiaire: this.effectifStagiaire,
        effectifTotalHomme: this.effectifTotalHomme,
        effectifTotalFemme: this.effectifTotalFemme,
        effectifTotal: this.effectifTotal,
      }
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // eslint-disable-next-line no-unused-vars
    calculFemme(tot, hom, fem) {
      this.fem = tot - hom;

      console.log(hom, tot, this.fem);
    },
  },
};
</script>

<style scoped></style>
