<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-4">
      <besoin-personnel-editor-dialog
        :hideAlphabetisation="hideAlphabetisation"
        :hideNiveau="hideNiveau"
        v-show="false"
        @save="saveBesoinPersonnel"
        :title="
          activeItem != null && activeItem.besoinPersonnels == null
            ? 'Ajouter un besoin en personnel'
            : 'Editer un besoin en personnel'
        "
        ref="editor"
        :item="activeItem"
      />
      <slot name="header" :addBesoinPersonnel="addBesoinPersonnel">
        <!-- <h6 class="mb-0 text-uppercase flex-fill">{{ title }}</h6> -->
        <button
          @click.prevent="addBesoinPersonnel()"
          type="button"
          class="btn btn-primary"
        >
          Ajouter
        </button>
      </slot>
    </div>
    <div class="mb-5">
      <!-- <div class="d-flex justify-content-between flex-nowrap mb-4">
      <h5 class="mb-0 bg-warning text-uppercase p-1 flex-fill">BesoinPersonnel</h5>
      <a class="btn btn-primary rounded-0 btn-sm"><i class="bi bi-clipboard-plus"></i></a>
    </div> -->

      <template v-if="besoinPersonnels.length != 0">
        <template v-for="(besoin, index) in besoinPersonnels">
          <div
            :key="index"
            class="border py-2 px-2 d-flex justify-content-between mb-2 align-items-center"
          >
            <div class="col-sm-12">
              <h6 class="mb-0">Categorie : {{ besoin.categorie }}</h6>
              <small>Metier : {{ besoin.poste.libelle }}</small>
              <hr />
              <small>Détails</small>
              <table class="table table-borderless">
                <thead class="border-bottom border-top">
                  <tr>
                    <th scope="col">Qualification</th>

                    <th scope="col">Homme</th>
                    <th scope="col">Femme</th>
                    <th scope="col">Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <span> {{ besoin.qualification || "-" }}</span>
                    </td>
                    <td>
                      <span> {{ besoin.homme || "-" }}</span>
                    </td>
                    <td>
                      <span> {{ besoin.femme || "-" }}</span>
                    </td>
                    <td>
                      <span>
                        {{ parseInt(besoin.femme) + parseInt(besoin.homme) || "-" }}</span
                      >
                    </td>
                    <td>
                      <a
                        @click.prevent="updateBesoinPersonnel(besoin)"
                        class="btn btn-success rounded-0 btn-sm"
                        ><i class="bi bi-pencil-square"></i
                      ></a>

                      <a
                        @click.prevent="deleteBesoinPersonnel(besoin, index)"
                        class="btn btn-danger rounded-0 btn-sm"
                        ><i class="bi bi-trash"></i
                      ></a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- <div>
              <a
                @click.prevent="updateBesoinPersonnel(besoin)"
                class="btn btn-success rounded-0 btn-sm"
                ><i class="bi bi-pencil-square"></i
              ></a>
              <a
                @click.prevent="deleteBesoinPersonnel(besoin)"
                class="btn btn-danger rounded-0 btn-sm"
                ><i class="bi bi-trash"></i
              ></a>
            </div> -->
          </div>
        </template>
      </template>
      <div class="mb-4 bg-light" v-if="besoinPersonnels.length == 0">
        <div
          style="min-height: 150px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Aucun besoin en personnel à afficher</span>
          <button
            @click.prevent="addBesoinPersonnel()"
            type="button"
            class="btn btn-sm btn-outline-primary"
          >
            Ajouter
          </button>
          <!-- {{ value }} -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import BesoinPersonnelEditorDialog from "./editor/dialog/BesoinPersonnelEditorDialog.vue";
export default {
  props: ["value", "entreprise"],

  components: { BesoinPersonnelEditorDialog },
  data() {
    return {
      activeItem: {},
      besoinPersonnels: [...this.value],
      hideNiveau: false,
      hideAlphabetisation: false,

      // tailleBesoinPersonne: "",
    };
  },
  watch: {
    besoinPersonnels(val) {
      this.$emit("input", val);
    },
  },
  computed: {
    ...mapGetters({}),
    emptyBesoinPersonnel() {
      return {};
    },
  },
  methods: {
    ...mapActions({
      createOrUpdateBesoin: "besoin/createOrUpdateBesoin",
      deleteBesoin: "besoin/deleteBesoin",
    }),
    updateBesoinPersonnel(emp) {
      this.activeItem = { ...emp };
      this.$refs.editor.show();
    },
    addBesoinPersonnel() {
      this.activeItem = { ...this.emptyBesoinPersonnel };
      this.$refs.editor.show();
    },
    saveBesoinPersonnel(emp) {
      //   this.besoinPersonnels = this.besoinPersonnels.filter(item => item.poste.id != emp.poste.id)
      //   this.besoinPersonnels.push(emp);
      //   this.activeItem = { ...this.emptyBesoinPersonnel };
      //   this.$emit("input", { ...this.value, ...this.besoinPersonnels });

      this.createOrUpdateBesoin({
        ...emp,
        entreprise: this.entreprise,
      }).then((savedItem) => {
        emp = savedItem;
        this.besoinPersonnels = this.besoinPersonnels.filter((item) => item.id != emp.id);
        this.besoinPersonnels.push(emp);
        this.activeItem = { ...this.emptyBesoinPersonnel };
        this.$emit("input", {
          ...this.value,
          ...this.besoinPersonnels,
        });
      });
    },
    deleteBesoinPersonnel(emp, index) {
      //   this.besoinPersonnels = this.besoinPersonnels.filter((item, idx) => index != idx);
      this.deleteBesoin(emp).then((el) => {
        this.pieces = this.pieces.filter((item) => item.id != emp.id);
        this.activeItem = { ...this.emptyBesoinPersonnel };
        this.$emit("input", {
          ...this.value,
          ...this.besoinPersonnels,
        });
      });
    },
  },
};
</script>
