<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '50vw' }"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Choisir un fichier">
          <FilePondUploader
            ref="fileUploader"
            class="col-12 md:col-12"
            @save="fileSaved"
          />
        </PTabPanel>
      </PTabView>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import FilePondUploader from "@/components/uploader/FilePondUploader.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Joindre le fichier de recommandations",
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "demandeurSelector",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ],
    },
  },
  components: {
    FilePondUploader,
  },
  data() {
    return {
      display: false,
      displayCamera: false,
      activeTab: 0,
      error: false,
      imageUrl: null,
      participants: [],
    };
  },
  created() {},
  watch: {
    items() {
      this.participants = [...this.items];
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return `regroupements=NON_AFFECTE`;
    },
    resetContent() {
      this.imageUrl = null;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // fileSaved(files) {
    //   // this.$refs.fileUploader.getFilesUrls()
    //   if (files.length > 0) {
    //     this.imageUrl = files[0];
    //     this.error = !(typeof urlSignature == "string" || urlSignature instanceof String);
    //   }
    // },
    pictureSaved(fileUrl) {
      this.imageUrl = fileUrl;
    },
    fileSaved(fileUrls) {
      this.imageUrl =
        fileUrls instanceof Array && fileUrls.length != 0 ? fileUrls[0] : fileUrls;
    },
    takePicture() {
      this.displayCamera = true;
    },
    async save() {
      this.$emit("save", this.imageUrl);
      this.hide();
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
