<template>
  <div>
    <PDialog
      :header="title"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '50vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row grid-md">
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="nom"
                        class="form-control"
                        name="nom"
                        v-model="editableItem.nom"
                      />
                      <label for="nom">Nom</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="prenom"
                        class="form-control"
                        name="prenom"
                        v-model="editableItem.prenom"
                      />
                      <label for="prenom">Prénom</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <c-select
                        id="nationalite"
                        name="nationalite"
                        aria-label="Votre pays de résidence"
                        option-label="nom"
                        option-value="id"
                        v-model="editableItem.nationalite"
                        :options="pays"
                        class="form-select"
                      />
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                      <label for="nationalite">Nationalité</label>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <select
                        :value="editableItem.sexe"
                        @input="handleInput"
                        class="form-select form-select mb-4"
                        id="sexe"
                        name="sexe"
                        aria-label="Votre sexe"
                      >
                        <option value="M">Masculin</option>
                        <option value="F">Féminin</option>
                      </select>
                      <label for="sexe">Sexe</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <div class="field col-12 md:col-12">
                        <DatePicker
                          class="flex-grow"
                          :first-day-of-week="2"
                          :masks="{ title: 'DD-MM-YYYY' }"
                          locale="fr"
                          v-model="editableItem.dateNaissance"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <div class="form-floating mb-3 flex w-full">
                              <input
                                class="form-control"
                                :value="inputValue"
                                required
                                type="text"
                                v-on="inputEvents"
                              />
                              <label for="titre">Date naissance</label>
                            </div>
                          </template>
                        </DatePicker>
                      </div>

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="lieuNaissance"
                        class="form-control"
                        name="lieuNaissance"
                        v-model="editableItem.lieuNaissance"
                      />
                      <label for="lieuNaissance">Lieu de naissance</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required|email" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        id="enauk"
                        class="form-control"
                        name="email"
                        v-model="editableItem.email"
                      />
                      <label for="email">Email</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        id="titre"
                        class="form-control"
                        name="titre"
                        v-model="editableItem.titre"
                      />
                      <label for="titre">Titre</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6 field">
                  <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        id="phone"
                        class="form-control"
                        name="phone"
                        v-model="editableItem.phone"
                      />
                      <label for="nombreJoursOuvres">Téléphone</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                    <!-- <small class="text-help"></small> -->
                  </ValidationProvider>
                </div>

                <div class="col-sm-6 field">
                  <ValidationProvider rules="required|numeric" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <input
                        type="text"
                        v-uppercase
                        id="whatsapp"
                        class="form-control"
                        name="whatsapp"
                        v-model="editableItem.whatsapp"
                      />
                      <label for="whatsapp">Whatsapp:</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>

                <div class="col-sm-12 field mt-3">
                  <ValidationProvider rules="" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <textarea
                        type="text"
                        rows="10"
                        style="height: 120px"
                        id="observation"
                        class="form-control"
                        name="observation"
                        v-model="editableItem.observation"
                      />
                      <label for="observation">Observation</label>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { mapActions, mapGetters } from "vuex";
import AsyncSearchInput from "../../../../common/AsyncSearchInput.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  components: {
    AsyncSearchInput,
    DatePicker,
  },
  props: {
    title: {
      type: String,
      default: "Ajouter une mission",
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
    hidePlacementField: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      deep: true,
    },
  },
  created() {
    this.fetchNationalites();
  },
  computed: {
    ...mapGetters({
      nationalites: "nationalite/nationalites",
    }),
    pays() {
      return [{ id: null, code: null }, ...this.nationalites];
    },
  },
  methods: {
    ...mapActions({
      fetchNationalites: "nationalite/fetchNationalites",
    }),
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
