<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.responsable"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="responsable"
                    name="responsable"
                    placeholder="Responsable"
                  />
                  <label for="responsable">Responsable</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateMiseEnOeuvre">Date de mise en oeuvre</label>
                    <p-calendar
                      id="dateMiseEnOeuvre"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateMiseEnOeuvre"
                      name="dateMiseEnOeuvre"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="echeance">Date d'échéance</label>
                    <p-calendar
                      id="echeance"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.echeance"
                      name="echeance"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-md-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PDropdown
                    class="col-12"
                    v-model="editableItem.etatMiseEnOeuvre"
                    :options="etatMiseEnOeuvres"
                    optionLabel="name"
                    placeholder="Etat de mise en oeuvre"
                    optionValue="code"
                  />
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <!-- <label for="typeAction">Type d'actions</label> -->
                </div>
              </ValidationProvider>
            </div>

            <!-- <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.responsable"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="responsable"
                    name="responsable"
                    placeholder="Responsable"
                  />
                  <label for="responsable">Responsable</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div> -->
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.commentaire"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="commentaire"
                    name="commentaire"
                    placeholder="commentaire"
                  />
                  <label for="commentaire">Commentaire</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    planaction: {
      type: Object,
      default: () => {},
    },
    constat: {
      type: Object,
      default: () => {
        false;
      },
    },
  },
  mixins: [],
  components: {
    Modal,
    //  CSelect,
    MetierSelect,
  },
  data() {
    return {
      editableItem: {},
      etatMiseEnOeuvres: [
        { code: "NON_DEMARRE", name: "Non Démarré" },
        { code: "ENCOURS", name: "En cours" },
        { code: "FAIT", name: "Fait" },
      ],
    };
  },
  created() {
    // this.fetchEntrepriseAudits(this.entreprise.id)
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({
      audits: "audit/audits",
    }),

    total() {
      return this.editableItem.homme + this.editableItemfemme;
    },
  },
  methods: {
    ...mapActions({
      getEntrepriseConstat: "constat/getEntrepriseConstat",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
