<template>
  <div>
    <PDialog :header="'Prorogation de l\'offre #' + editableItem.reference" :modal="true" :visible.sync="display" :contentStyle="{overflow: 'visible'}" :containerStyle="{width: '30vw'}">
     <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()" >
              <template>
                 <div class="row">
                <div class="col-sm-12">
                  <label class="h6 mb-2">De combien de jours voulez-vous proroger l'offre?</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="col-sm-12 mb-sm-12">
                    <div class="form-floating mb-3">
                      <input class="form-control" input="number" v-model="editableItem.dureeProlongation" :min="0" :max="100" />
                      <label>Durée prolongation (en jours)</label>
                        <span
                          v-for="(err, index) in errors"
                          :key="index"
                          class="text-danger"
                          >{{ err }}</span
                        >
                        <div id="emailHelp" class="form-text">Entrer un nombre positif pour dimimuer le délai et un nombre négatif pour augmenter le délai.</div>
                  </div>
                </div>
                  </ValidationProvider>
                </div>
              </div>
              </template>
            </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton label="Quitter" icon="pi pi-times" @click="cancel" class="p-button-text"/>
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

import { paginatorMixin } from '@/mixins/paginator-mixin'
export default {
  props: {
    title: {
      type:  String,
      required: true
    },
    size: {
      type:  String,
      default: 'md'
    },
    item: {
      type:  Object,
      default: () => ({ })
    }
  },
  mixins: [paginatorMixin],
  components: {
    VueEditor
  },
  data () {
    return {
      display: false,
      editableItem: {},
      search: '',
      viewMode: 'card',//card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0
    }
  },
  watch: {
    item: {
        handler() {
            this.editableItem = { ...this.item }

        },
        immediate: true
    } 
  },
  created () {
  },
  methods: {
    resetContent(){
        this.editableItem = {}
    },
    show () {
      this.resetContent()
        this.display = true
    },
    hide () {
        this.display = false
    },
    cancel () {
      this.hide()
      this.$emit('canceled')
    },
    async isCompleted () {
      const valid = await this.$refs.observer.validate()
      return valid
    },
    async save(){
      if(!await this.isCompleted()) return;
      this.$emit('save', {
        ...this.editableItem
        // dossiers: this.selectedDemandeurs
      })
      this.editableItem = {}
      this.hide()
    },
  }
  
}
</script>