<template>
  <PDialog
    class="stepper-dialog"
    :header="title"
    :modal="true"
    :visible.sync="display"
    :containerStyle="{ width: '60vw' }"
  >
    <!-- :contentStyle="{ overflow: [0].includes(activeStepIndex) ? 'visible' : 'auto' }" -->
    <template #header>
      <div class="card px-0 border-0 mb-2 w-100">
        <div>
          <h4 class="d-flex justify-content-between">
            {{ title }}
            <PButton
              @click="cancel()"
              icon="pi pi-times"
              class="p-button-rounded p-button-outlined p-button-danger"
            />
          </h4>
          <PDivider />
        </div>
        <DStepper
          class="shadow-light"
          :steps="steps"
          :activeStepIndex="activeStepIndex"
          :readonly="false"
        />
      </div>
    </template>
    <template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.lieu"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="lieu"
                    name="lieu"
                    placeholder="lieu"
                  />
                  <label for="lieu">Lieu</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.nomDirigeant"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="nomDirigeant"
                    name="nomDirigeant"
                    placeholder="Nom Dirigeant"
                  />
                  <label for="nomDirigeant">Nom Dirigeant</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateDebut">Date debut</label>
                    <p-calendar
                      id="dateDebut"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateDebut"
                      name="dateDebut"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <label for="dateFin">Date Fin</label>
                    <p-calendar
                      id="dateFin"
                      class="col-12 md:col-12"
                      :showIcon="true"
                      size="sm"
                      locale="fr"
                      v-model="editableItem.dateFin"
                      name="dateFin"
                      dateFormat="dd/mm/yy"
                    ></p-calendar>
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <label>Participants</label>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <PTextarea
                      class="col-12"
                      id="participants"
                      v-model="editableItem.participants"
                      rows="3"
                    />
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <label>Commentaire</label>
              <ValidationProvider v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <div class="field col-12 md:col-12">
                    <PTextarea
                      class="col-12"
                      id="commentaire"
                      v-model="editableItem.commentaire"
                      rows="5"
                    />
                  </div>

                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
    <template #footer>
      <PButton label="Annuler" icon="pi pi-times" @click="cancel" class="p-button-text" />
      <PButton
        label="Précédent"
        icon="pi pi-chevron-left"
        :disabled="activeStepIndex <= 0"
        class="p-button-outlined"
        @click.prevent="goToPrevious"
      />
      <PButton
        label="Suivant"
        icon="pi  pi-chevron-right"
        v-if="activeStepIndex < steps.length - 1"
        @click.prevent="goToNext"
      />
      <PButton
        label="Valider"
        icon="pi pi-check"
        @click="save"
        autofocus
        v-if="activeStepIndex >= steps.length - 1"
      />
    </template>
  </PDialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";
import DStepper from "../../../../../common/DStepper.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    entreprise: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [],
  components: {
    DStepper,
    MetierSelect,
  },
  data() {
    return {
      display: true,
      editableItem: {},
      activeStepIndex: 0,
      qualification: [
        { code: "SQ", name: "Sans Qualification" },
        { code: "AQ", name: "Avec Qualification" },
      ],
    };
  },
  created() {
    // this.fetchEntrepriseAudits(this.entreprise.id);
  },
  watch: {
    // item() {
    //   this.editableItem = { ...this.item };
    // },

    "editableItem.dateDebut": {
      handler(val) {
        this.editableItem.dateFin = val;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters({
      // romes: "rome/romes",
    }),
    steps() {
      let steps = [
        {
          name: "PROGRAMMER",
          label: "PROGRAMMER L'ACTIVITE",
          subtitle: "Définier les infos de l'activité",
          to: "#programmer-activiter",
          completed: this.activeStepIndex >= 0,
          command: () => {},
        },
        {
          name: "CANNAUX",
          label: "CAUNAUX DE NOTIFICATIONS",
          subtitle: "Choix des cannaux de notifications",
          to: "#cannaux-notification",
          completed: this.activeStepIndex >= 1,
          command: () => {},
        },
      ];

      return steps;
    },
  },
  methods: {
    ...mapActions({
      fetchEntrepriseAudits: "audit/fetchEntrepriseAudits",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async goToNext() {
      if (!(await this.isCompleted(this.activeStepIndex))) return;
      this.activeStepIndex += this.activeStepIndex < this.steps.length - 1 ? 1 : 0;
    },
    async goToPrevious() {
      // if(!await this.isCompleted(this.activeStepIndex)) return;
      this.activeStepIndex -= this.activeStepIndex > 0 ? 1 : 0;
    },
    resetContent() {
      this.activeStepIndex = 0;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("save", this.editableItem);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted(step) {
      const valid = await this.$refs["observer" + step].validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted(this.steps.length - 1))) return;

      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
};
</script>
