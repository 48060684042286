<template>
    <div class="">
    <ValidationObserver ref="observer"  v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()" >
        <div class="row">
          <div class="col-sm-12 mb-4">
            <h5>Voulez-vous que votre entreprise participe au programme Azoli?</h5>
            <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-check form-check-inline">
                  <input v-model="isCandidatAzoli" class="form-check-input" type="radio" id="isCandidatAzoli1" name="isCandidatAzoli" :value="true">
                  <label class="form-check-label" for="isCandidatAzoli1">Oui</label>
                </div>
                <div class="form-check form-check-inline">
                  <input v-model="isCandidatAzoli" class="form-check-input" type="radio" id="isCandidatAzoli2" name="isCandidatAzoli" :value="false">
                  <label class="form-check-label" for="isCandidatAzoli2">Non</label>
                </div>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
  
            </ValidationProvider>
          </div>
        </div>
      </form>
    </ValidationObserver>
    </div>
  </template>
  
  <script>
  import { setepperFormMixin } from '@/mixins/stepper-form-mixin'
  import { mapGetters, mapActions } from "vuex"
  
  export default {
    components: { },
    props: ['value'],
    mixins: [ setepperFormMixin ],
    data () {
      return {
        isCandidatAzoli: this.value.isCandidatAzoli,
        editableItem: this.value
      }
    },
    computed: {
      ...mapGetters({
        instructions: 'instruction/instructions',
      }),
      niveauInstructions(){
        return this.editableItem.typeActivation != 'CAS_SPECIAL'? this.instructions : this.instructions.slice(0, 10)
      },
      estPeuOuPasInstruit(){
        return this.isCandidatAzoli != null && ( !this.isCandidatAzoli  || (this.isCandidatAzoli && this.editableItem.niveauInstruction != null && this.editableItem.niveauInstruction <= 10))
      }
    },
    watch: {
      isCandidatAzoli () {
        this.editableItem.isCandidatAzoli = this.isCandidatAzoli
        this.$emit('input', this.editableItem)
        if(this.isCandidatAzoli !== null && !this.isCandidatAzoli){
          this.editableItem.niveauInstruction = null
        }
      }
    },
    created () {
      this.fetchInstructions()
    },
    methods: {
      ...mapActions({
        fetchInstructions: 'instruction/fetchInstructions',
      }),
      handleInput(e){
        if (e.target.name === 'niveauInstruction') {
          this.editableItem[e.target.name] = parseInt(e.target.value)
        }
        else {
        this.editableItem[e.target.name] = e.target.value
        }
  
        this.$emit('input', this.editableItem)
      }
    }
  }
  </script>
  
  <style scoped>
  </style>
  