<template>
  <div class="containe">
    <div class="row">
      <div class="col">
        <div class="card">
          <ConstatEditor
            :title="
              activeContenu && activeContenu.id
                ? 'Ajouter un constant'
                : 'Modifier un constat'
            "
            :item="activeConsigne"
            ref="constatEditor"
            @save="saveConsigneItem"
          />
          <PDataTable
            class="shadow-none"
            :value="domaineAnalyse"
            stripedRows
            editMode="row"
            dataKey="id"
            :editingRows.sync="editingRows"
          >
            <!-- <PColumn :expander="true" /> -->
            <PColumn
              field="typeConsigne"
              :styles="{ width: colWidth }"
              header="Domaine d'analyse"
            >
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.typeConsigne.libelle }}
                </a>
              </template>
            </PColumn>
            <!-- <PColumn field="contenu" :styles="{ width: colWidth }" header="Contenu">
                <template #body="slotProps">
                    <PBadge v-if="slotProps.data.contenus.length>0" :value="slotProps.data.contenus.length" severity="primary"></PBadge>
                </template>
              </PColumn> -->
            <PColumn
              field="dateDebut"
              :styles="{ width: colWidth }"
              header="Date Début"
            >
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field]
                    | moment("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn
              field="dateFin"
              :styles="{ width: colWidth }"
              header="Date Fin"
            >
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field]
                    | moment("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="etat" header="Etat">
              <template #body="slotProps">
                <!-- {{ slotProps.data.etat || 'N/A' }} -->
                <PBadge
                  :value="slotProps.data.etat"
                  :severite="slotProps.data.etat == 'TERMINE' ? '' : 'info'"
                />
              </template>
            </PColumn>
            <PColumn field="fullFileUrl" header="Pièce jointe">
              <template #body="slotProps">
                <a
                  v-if="slotProps.data.fullFileUrl"
                  href="#"
                  @click.prevent="
                    downloadFile({ url: slotProps.data.fullFileUrl })
                  "
                  ><i
                    class="text-primary bi bi-file-earmark-arrow-down"
                    style="font-size: 1.6rem"
                  ></i
                ></a>
                <!-- <PBadge :value="slotProps.data.etat" :severite="slotProps.data.etat=='TERMINE'? '':'info'" /> -->
              </template>
            </PColumn>
            <PColumn
              field="observation"
              :styles="{ width: colWidth }"
              header="Observation"
            >
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </PColumn>
            <PColumn :styles="{ width: colWidth }" header="Action">
              <template #body="slotProps">
                <div class="d-flex justify-space-between">
                  <PButton
                    @click.prevent="$refs.constatEditor.show()"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary"
                    @click="editConsigneItem(slotProps.data)"
                  />
                  <!-- <PButton icon="pi pi-plus" class="p-button-rounded p-button-outlined p-button-primary" @click="addContenuConsigneItem(slotProps.data)" /> -->
                  <PButton
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    @click="confirmDeleteItem(slotProps.data)"
                  />
                </div>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConstatEditor from "./editor/ConstatEditor.vue";
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  components: {
    ConstatEditor,
  },
  data() {
    return {
      display: false,
      // constats: [...this.value],
      domaineAnalyse: [...this.value],
      expandedRows: [],
      editingRows: [],
      activeConsigne: null,
      activeContenu: null,
      activeIndex: null,
    };
  },
  computed: {
    colWidth() {
      return "100%";
    },
    etats() {
      return [
        {
          value: "NON_REALISE",
          label: "Non Réalisé",
        },
        {
          value: "EN_COURS",
          label: "En cours",
        },
        {
          value: "TERMINE",
          label: "Terminé",
        },
      ];
    },
    emptyDomaineAnalyse() {
      return {
        demandeur: null,
        observation: null,
        consigne: null,
        dateDebut: null,
        dateFin: null,
        etat: null,
      };
    },
  },
  created() {
    this.fetchDomaineAnalyses;
  },
  methods: {
    ...mapActions({
      fetchDomaineAnalyses: "domaineanalyse/fetchDomaineAnalyses",
      createContenuConsigne: "consigne/createContenuConsigne",
      updateContenuConsigne: "consigne/updateContenuConsigne",
      deleteContenuConsigne: "consigne/deleteContenuConsigne",
      updateOrCreateContenuConsigne: "consigne/updateOrCreateContenuConsigne",
      updateConsigne: "consigne/updateConsigne",
      downloadFile: "media/downloadFile",
    }),
    onRowExpand() {},
    onRowCollapse() {},
    toggleExpandRow(item) {
      console.log(this.expandedRows);
      const index = this.expandedRows.indexOf((el) => el.id == item.id);
      if (index != -1) {
        this.expandedRows.push(item);
      } else {
        this.expandedRows = this.expandedRows.filter((el) => el.id != item.id);
      }
    },
    editConsigneItem(consigne) {
      console.log(consigne);
      this.activeConsigne = { ...consigne };
      this.$refs.constatEditor.show();
    },
    removeContenuConsigneItem(contenu, consigne) {
      this.deleteContenuConsigne(contenu).then(() => {
        this.constats = this.constats.map((presc) => {
          if (consigne.id == presc.id) {
            presc.contenus = presc.contenus.filter((el) => el.id != contenu.id);
          }
          return presc;
        });
        this.$toast.success({
          message: "Contenu supprimé avec succès!",
        });
      });
    },
    editContenuConsigneItem(contenu, consigne) {
      this.activeContenu = {
        ...contenu,
        consigne,
      };
      this.$refs.contenuConstatEditor.show();
    },
    addContenuConsigneItem(consigne) {
      this.activeContenu = {
        ...this.emptyContenuConsigne,
        consigne,
      };

      this.$refs.contenuConstatEditor.show();
    },
    async saveContenuConsigneItem(item) {
      let func = this.createContenuConsigne;

      if (item.id != null) {
        func = this.updateContenuConsigne;
      }

      func(item)
        .then((contenu) => {
          let contenus = item.consigne.contenus;
          this.constats = this.constats.map((presc) => {
            if (presc.id == item.consigne.id) {
              let replaced = false;
              presc.contenus = presc.contenus.map((el) => {
                if (el.id == contenu.id) replaced = true;
                return el.id == contenu.id ? contenu : el;
              });

              if (!replaced) {
                contenus.push(contenu);
                presc.contenus = contenus;
              }
            }
            this.activeContenu = {};
            return presc;
          });
          this.$toast.success({
            message: "Opération réussie",
          });
        })
        .catch((err) => {
          this.$toast.error({
            message: "Une erreur s'est produite." + err,
          });
        });
    },
    async saveConsigneItem(item) {
      this.updateConsigne(item)
        .then((consigne) => {
          this.constats = this.constats.map((presc) => {
            return presc.id != consigne.id ? presc : consigne;
          });
        })
        .catch((err) => {
          this.$toast.error({
            message: "Une erreur s'est produite." + err,
          });
        });
    },
  },
};
</script>
