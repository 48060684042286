<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <h6>Métier</h6>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form- mb-3">
                  <metier-select v-model="editableItem.poste" :options="romes">
                    <template #label>
                      <label for="metier">Métier</label>
                    </template>
                  </metier-select>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label>Type de qualification</label>
                <div class="form-floating mb-3">
                  <p-dropdown
                    class="col-sm-12"
                    v-model="editableItem.qualification"
                    :options="qualification"
                    optionLabel="name"
                    :editable="false"
                    optionValue="code"
                    ><template #label>
                      <label>Qualification</label>
                    </template>
                  </p-dropdown>

                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.homme"
                    @input="handleInput"
                    type="number"
                    v-uppercase
                    class="form-control"
                    id="homme"
                    name="homme"
                    placeholder="Besoin Homme"
                  />
                  <label for="homme">Besoin Homme</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-6">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.femme"
                    @input="handleInput"
                    type="number"
                    v-uppercase
                    class="form-control"
                    id="femme"
                    name="femme"
                    placeholder="Besoin Femme"
                  />
                  <label for="femme">Besoin Femme</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>
          
            <div class="col-sm-6 field">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div>Date début période</div>
                  <p-calendar
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    name="dateDu"
                    :manualInput="false"
                    v-model="editableItem.dateDu"
                    :showWeek="true"
                    />  
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              </ValidationProvider>
            </div>
            <div class="col-sm-6 field">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div>Date fin période</div>
                  <p-calendar
                    class="col-12 md:col-12"
                    :showIcon="true"
                    size="sm"
                    locale="fr"
                    name="dateAu"
                    :manualInput="false"
                    v-model="editableItem.dateAu"
                    :showWeek="true"
                    />  
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
              </ValidationProvider>
            </div>
            <!-- <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="
                      parseInt(editableItem.femme) + parseInt(editableItem.home)
                    "
                    @input="handleInput"
                    type="number"
                    v-uppercase
                    class="form-control"
                    id="total"
                    name="total"
                    placeholder="Besoin Total"
                    readonly
                  />
                  <label for="total">Besoin Total</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div> -->
          </div>

          <div class="d-grid mt-4 gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    hideAlphabetisation: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [],
  components: {
    Modal,
    //  CSelect,
    MetierSelect,
  },
  data() {
    return {
      editableItem: {},
      qualification: [
        { code: "SQ", name: "Sans Qualification" },
        { code: "AQ", name: "Avec Qualification" },
      ],
    };
  },
  created() {
    this.fetchRomes();
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
      console.log(this.editableItem.femme);
    },
  },
  computed: {
    ...mapGetters({
      romes: "rome/romes",
    }),

    total() {
      return this.editableItem.homme + this.editableItemfemme;
    },
  },
  methods: {
    ...mapActions({
      fetchEthnies: "ethnie/fetchEthnies",
      fetchRomes: "rome/fetchRomes",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
