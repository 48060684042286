/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid bg-white">
    <p-confirmDialog></p-confirmDialog>
    <div class="container- mt-1">
      <p-progress-bar v-if="load" style="height: 0.4em" mode="indeterminate" />
      <p-divider />
      <template v-if="!load">
        <div class="bg-">
          <div>
            <!-- <h4>LEVER DE CRITICITE</h4> -->

            <PTag class="badge col-12" :class="constat.codeCouleurCritique">
              <h4>
                {{
                  existsLeveNonConformite
                    ? "Levée de non conformité n'est pas disponible"
                    : "Procéder à la levée de non conformité"
                }}
              </h4>
            </PTag>
          </div>
          <hr />
          <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
            <div class="my-1" :class="getcollorCriticite">
              <!-- Criticité : <PTag :value=noteRisqueLever></PTag> -->
              <PTag class="badge" :class="getcollorCriticite">
                Criticité : {{ noteRisqueLever }}
              </PTag>
            </div>
            <div class="btn-group" :class="getcollorRisque">
              <PTag class="badge" :class="getcollorRisque">
                Risque : {{ noteRisqueLever }}
              </PTag>
            </div>
          </div>
          <div
            class="bg-white p-3 border"
            :class="{
              'inatif-table': existsLeveNonConformite,
            }"
          >
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="this.evaluationsConsat"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>

                <template #cell(codeCouleurLever)="{ item: evaluation }">
                  <PBadge
                    size="large"
                    :class="evaluation.codeCouleurLever"
                    :value="evaluation.noteLever"
                  >
                  </PBadge>
                </template>

                <template #cell(Notation)="row">
                  <div class="d-flex justify-content-end">
                    <div class="col-md-12 col-md-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="mb-3">
                          <PRating
                            v-model="row.item.noteLever"
                            @change="calculCriticite(row.item)"
                            :stars="10"
                          />
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
              <div class="my-1 flex-fill"></div>
              <div class="btn-group">
                <button
                  type="button"
                  @click.stop="validerEvaluation()"
                  class="style_btn btn btn-primary"
                >
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import ConstatEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/ConstatEditorDialog.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CandidatureList from "./CandidatureList.vue";

import { VueEditor } from "vue2-editor";
import { FILE_UPLOAD_API_URL } from "../../../config/api-config";

import OffreDetailViewer from "../../portail/OffreDetailViewer.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
    audit: {
      type: Object,
      required: false,
    },
    constat: {
      type: Object,
      required: false,
    },
  },
  components: {
    ConstatEditorDialog,
    FicheAzoliReport,
    ReportHeader,
    VueEditor,
    OffreDetailViewer,
    CandidatureList,
    CAutoComplete,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      activeItem: {},
      activeConstat: {},
      evaluations: [],
      constat: [],
      // note: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }],
      noteLever: "",
      load: false,
      notes: "",
      noteRisqueLever: this.constat.noteRisqueLever,
      noteRisqueLever: this.constat.noteCriticiteLever,
      note_s: "",
      note_d: "",
      note_o: "",
    };
  },
  created() {
    this.init();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    constat(val) {
      if (val) {
        this.noteRisqueLever = val.noteRisqueLever;
        this.noteRisqueLever = val.noteCriticiteLever;
        console.log(val);
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      constats: "constat/constats",
      criteres: "critere/criteres",
      evaluationsConsat: "evaluationConstat/evaluations",
      actionrequises: "actionrequise/actionrequises",
      pagination: "demandeur/pagination",

      romes: "rome/romes",
    }),
    existsLeveNonConformite() {
      return (
        this.constat.codeCouleurCritique == "jaune" ||
        this.constat.codeCouleurCritique == "vert"
      );
    },
    getcollorCriticite() {
      switch (true) {
        case 0 <= this.noteRisqueLever && this.noteRisqueLever <= 30:
          this.constat.codeCouleurCritiqueLever = "vert";
          return "vert"; //vert
        case 30 < this.noteRisqueLever && this.noteRisqueLever <= 40:
          this.constat.codeCouleurCritiqueLever = "jaune";
          return "jaune"; //jaune
        case 40 < this.noteRisqueLever && this.noteRisqueLever < 50:
          this.constat.codeCouleurCritiqueLever = "orange";
          return "orange"; // orange
        case 50 <= this.noteRisqueLever && this.noteRisqueLever <= 100:
          this.constat.codeCouleurCritiqueLever = "rouge";
          return "rouge"; // rouge
      }
      console.log(this.constat.codeCouleurCritiqueLever);
    },
    getcollorRisque() {
      switch (true) {
        case 0 <= this.noteRisqueLever && this.noteRisqueLever <= 300:
          this.constat.codeColeurRisqueLever = "vert";
          return "vert"; //vert
        case 300 < this.noteRisqueLever && this.noteRisqueLever <= 400:
          this.constat.codeColeurRisqueLever = "jaune";
          return "jaune"; //jaune
        case 400 < this.noteRisqueLever && this.noteRisqueLever < 500:
          this.constat.codeColeurRisqueLever = "orange";
          return "orange"; // orange
        case 500 <= this.noteRisqueLever && this.noteRisqueLever <= 1000:
          this.constat.codeColeurRisqueLever = "rouge";
          return "rouge"; // rouge
      }
      console.log(this.constat.codeColeurRisqueLever);
    },

    tableFields() {
      return [
        // "#",
        // 'index',
        { key: "codeCouleurLever", label: "Couleur" },
        { key: "critere.libelle", label: "Criticité et risque" },
        // { key: 'validePar', label: 'Validé par' },
        "Notation",
      ];
    },
    parDepartementEnregistresTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parCommuneEnregistresTableFields() {
      return [
        // 'index',
        { key: "commune", label: "Commune" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parAeEnregistresTableFields() {
      return [
        // 'index',
        { key: "ae", label: "AE" },
        { key: "M", label: "Hommes" },
        { key: "F", label: "Femmes" },
        { key: "total", label: "Total" },
      ];
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableFields() {
      return [
        // 'index',
        { key: "departement", label: "Département" },
        { key: "femmesCount", label: "Nombre femmes" },
        { key: "enfantsCount", label: "Nombre enfants" },
        // { key: 'total', label: 'Total' },
      ];
    },
    parDepartementEnregistresTableData() {
      return this.parDepartementEnregistres;
    },
    parCommuneEnregistresTableData() {
      return this.parCommuneEnregistres;
    },
    parAeEnregistresTableData() {
      return this.parAeEnregistres;
    },
    parDepartementFemmesEtEnfantsEntre3MoisEt3AnsTableData() {
      return this.parDepartementFemmesEtEnfantsEntre3MoisEt3Ans;
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchConstats: "constat/fetchConstats",
      getAuditConstat: "constat/getAuditConstat",
      fetchCriteres: "critere/fetchCriteres",
      fetchActionRequisesByConstat: "actionrequise/fetchActionRequisesByConstat",
      // createNewEvaluation: "historiqueevaluationConstat/createNewEvaluation",
      fetchEvaluationsByConstat: "evaluationConstat/fetchEvaluationsByConstat",
      createOrUpdateEvaluation: "evaluationConstat/createOrUpdateEvaluation",
    }),
    init() {
      this.load = true;
      console.log(this.constat);
      // this.fetchEvaluationsByConstat(this.constat);
      this.fetchCriteres().then(() => {
        // this.fetchActionRequisesByConstat(this.constat),
        this.load = false;
      });
    },
    validerEvaluation() {
      // if (this.noteRisqueLever != 0) {
      this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment levé la criticité? Cette opération est irreversible`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          console.log(this.activeConstat);
          this.activeConstat.lever = true;
          this.$emit("updateNote", this.activeConstat);
        },
      });

      // }
    },
    calculCriticite(payload) {
      console.log(payload);
      if (payload.critere.code == "O") {
        this.note_o = payload.noteLever;
      }
      if (payload.critere.code == "S") {
        this.note_s = payload.noteLever;
      }
      if (payload.critere.code == "D") {
        this.note_d = payload.noteLever;
      }

      this.noteCriticiteLever = this.note_o * this.note_s;
      this.noteRisqueLever = this.note_o * this.note_s * this.note_d;
      this.activeConstat = {
        ...this.constat,
        note_o: this.note_o,
        note_s: this.note_s,
        note_d: this.note_d,
        noteCriticiteLever: this.noteCriticiteLever,
        noteRisqueLever: this.noteRisqueLever,
        audit: this.audit,
      };
      payload = {
        ...payload,
        codeCouleurLever: this.getcollorVariable(payload.noteLever),
      };

      this.createOrUpdateEvaluation(payload);

      // this.$emit("updateNote", this.activeConstat);
    },
    getcollorVariable(note) {
      switch (true) {
        case 0 <= note && note <= 5:
          return "vert"; //vert
        case 5 < note && note <= 5.5:
          return "jaune"; //jaune
        case 5.5 < note && note < 8:
          return "orange"; // orange
        case 8 <= note && note <= 10:
          return "rouge"; // rouge
      }
      console.log(note);
    },
    validation() {},
    saveConstat(payload) {
      payload = {
        ...payload,
        audit: this.audit,
      };
      console.log(payload);

      this.createOrUpdateConstat(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "primary";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "ANNULE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },

    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "constat",
        search: this.search,
        fetcherMethod: "constat/fetchConstats",
        dataGetter: this.audit.constats,
        paginationGetter: "constat/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    getcollorCriticite1(payload) {
      switch (this.payload) {
        case 0 <= payload <= 30:
          return "#00561B"; //vert
        case 30 < payload <= 40:
          return "#FFFF00"; //jaune
        case 40 < payload < 50:
          return "#FAA401"; // orange
        case 50 <= payload <= 100:
          return "#FF0000"; // rouge
      }
    },

    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    addConstat() {
      // this.constat.push(emp)
      this.activeItem = {};
      this.$refs.editor.show();
    },
    updateConstat(payload) {
      // this.constat.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },

    update(constat) {
      this.activeItem = { ...constat };
      this.$refs.editor.show();
    },
  },
};
</script>

<style>
.vert {
  background: #00561b;
  color: white;
}

.jaune {
  background: #ffff00;
  color: black;
}

.orange {
  background: #faa401;
}

.rouge {
  background: #ff0000;
}

.inatif-table {
  pointer-events: none;
}
.inatif-buton {
  display: none;
}
</style>
