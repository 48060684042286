<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '50vw' }"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Prendre une photo">
          <a
            class="btn btn-warning rounded btn-sm me-1"
            v-tooltip.top="'Ajouter une photo'"
            @click.stop="takePicture"
            ><i class="bi bi-camera"></i>
          </a>
          <!-- <PImagePreview
            :src="imageUrl"
            v-if="!displayCamera && imageUrl != null"
            preview
          /> -->
          <TakePicture
            v-if="displayCamera"
            @save="pictureSaved"
            :showPreview="true"
            @close="displayCamera = false"
          />
        </PTabPanel>
        <PTabPanel header="Choisir une image">
          <FilePondUploader
            ref="fileUploader"
            class="col-12 md:col-12"
            @save="fileSaved"
          />
        </PTabPanel>
      </PTabView>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="d-flex">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    v-model="fileName"
                    type="text"
                    class="form-control"
                    id="fileName"
                    name="fileName"
                    placeholder="Nom du fichier"
                  />
                  <label for="nomDirigeant">Nom du fichier</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>
        </form>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import FilePondUploader from "@/components/uploader/FilePondUploader.vue";
import TakePicture from "./TakePicture.vue";

export default {
  props: {
    title: {
      type: String,
      default: "Prise de photo",
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "demandeurSelector",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ],
    },
  },
  components: {
    FilePondUploader,
    TakePicture,
  },
  data() {
    return {
      display: false,
      displayCamera: false,
      activeTab: 0,
      error: false,
      imageUrl: null,
      filename: null,
      participants: [],
    };
  },
  created() {},
  watch: {
    items() {
      this.participants = [...this.items];
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return `regroupements=NON_AFFECTE`;
    },
    resetContent() {
      this.imageUrl = null;
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // fileSaved(files) {
    //   // this.$refs.fileUploader.getFilesUrls()
    //   if (files.length > 0) {
    //     this.imageUrl = files[0];
    //     this.error = !(typeof urlSignature == "string" || urlSignature instanceof String);
    //   }
    // },
    pictureSaved(fileUrl) {
      this.imageUrl = fileUrl;
    },
    fileSaved(fileUrls) {
      this.imageUrl =
        fileUrls instanceof Array && fileUrls.length != 0 ? fileUrls[0] : fileUrls;
    },
    takePicture() {
      this.displayCamera = true;
    },
    async save() {
      if (!this.isCompleted) return;
      this.$emit("save", {
        fileUrl: this.imageUrl,
        fileName: this.fileName,
      });
      this.hide();
    },
  },
};
</script>

<style scoped>
#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}
</style>
