<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <!-- <block-maitrise-langues title="" v-model="editableItem.maitriseLangues" :hideAlphabetisation="true" /> -->
        <div class="row">
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.raisonSocial"
                  @input="handleInput"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="raisonSocial"
                  name="raisonSocial"
                  placeholder="Effectif total"
                  readonly
                />
                <label for="raisonSocial">Structure</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <hr />

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <textarea
                  :value="editableItem.adresseSiege"
                  @input="handleInput"
                  type="text"
                  v-uppercase
                  class="form-control"
                  id="adresseSiege"
                  name="adresseSiege"
                  size="4"
                  placeholder="Adresse géographique"
                ></textarea>
                <label for="adresseSiege">Adresse Géographique</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.telephoneDirigeant"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="telephoneDirigeant"
                  name="telephoneDirigeant"
                  placeholder="Contact du dirigeant"
                />
                <label for="telephoneDirigeant">Contact du dirigeant</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>

          <hr />
          <h5>Effectif total</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalHommePersonnel"
                  type="number"
                  v-uppercase
                  disabled
                  class="form-control"
                  id="effectifTotalHomme"
                  name="effectifTotalHomme"
                  placeholder="Effectif total homme"
                />
                <label for="effectifTotalHomme">Homme </label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalFemmePersonnel"
                  type="number"
                  v-uppercase
                  disabled
                  class="form-control"
                  id="effectifTotalFemme"
                  name="effectifTotalFemme"
                  placeholder="Effectif total femme"
                />
                <label for="effectifTotalFemme">Femme</label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalPersonnel"
                  type="number"
                  disabled
                  v-uppercase
                  class="form-control"
                  id="effectifTotal"
                  name="effectifTotal"
                  placeholder="Effectif total"
                />
                <label for="effectifTotalFemme">Total </label>
                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Existe-t-il des toilettes ? (Photo si possible)"
                v-model="editableItem.isToilette"
              >
              </c-yes-or-no-input>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isToilette">
              <ValidationProvider rules="" v-slot="{ errors }">
                <FilePondUploader
                  ref="fileUploader"
                  class="form-control col-12 md:col-12"
                  @save="fileSavedToitelle($event)"
                  :autoResize="true"
                  rows="8"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
            <br />
          </div>
          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="L’eau potable est-elle disponible sur le site ? (Photo si possible)"
                v-model="editableItem.isEauPotable"
              >
              </c-yes-or-no-input>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isEauPotable">
              <ValidationProvider rules="" v-slot="{ errors }">
                <FilePondUploader
                  ref="fileUploader"
                  class="form-control col-12 md:col-12"
                  @save="fileSavedEau($event)"
                  :autoResize="true"
                  rows="8"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </div>
          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Existe-t-il un dispositif de lavage des mains ? (Photo si possible)"
                v-model="editableItem.isLavageDeMain"
              >
              </c-yes-or-no-input>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isLavageDeMain">
              <ValidationProvider rules="" v-slot="{ errors }">
                <FilePondUploader
                  ref="fileUploader"
                  class="form-control col-12 md:col-12"
                  @save="fileSavedLavage($event)"
                  :autoResize="true"
                  rows="8"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </div>

          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Existe-t-il un dispositif de sécurité en cas d’incendie ? (Photo si possible)"
                v-model="editableItem.isSecurite"
              >
              </c-yes-or-no-input>
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isSecurite">
              <ValidationProvider rules="" v-slot="{ errors }">
                <FilePondUploader
                  ref="fileUploader"
                  class="form-control col-12 md:col-12"
                  @save="fileSavedSecurite($event)"
                  :autoResize="true"
                  rows="8"
                />

                <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                  err
                }}</span>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-sm-12">
            <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
            <div class="form-floating mb-3">
              <textarea
                :value="editableItem.observation"
                @input="handleInput"
                type="text"
                v-uppercase
                class="form-control"
                id="observation"
                name="observation"
                size="4"
                placeholder="Adresse géographique"
              ></textarea>
              <label for="observation">Observation</label>
              <!-- <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                > -->
            </div>
            <!-- </ValidationProvider> -->
          </div>

          <!-- {{ value }} -->
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CYesOrNoInput from "../../../../CYesOrNoInput.vue";
import FilePondUploader from "../../../../../components/uploader/FilePondUploaderOneFile.vue";
// import BlockMaitriseLangues from '../BlockMaitriseLangues.vue'
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {
    CYesOrNoInput,
    FilePondUploader,
    // BlockMaitriseLangues
  },
  data() {
    return {
      hasNip: null,
      pieces: [],
      editableItem: {},
    };
  },
  computed: {},
  created() {
    this.editableItem = {
      ...this.value,
      effectifTotalHommePersonnel:
        parseInt(this.value.effectifTotalHommeProd) +
        parseInt(this.value.effectifTotalHomme),
      effectifTotalFemmePersonnel:
        parseInt(this.value.effectifTotalFemmeProd) +
        parseInt(this.value.effectifTotalFemme),
      effectifTotalPersonnel:
        parseInt(this.value.effectifTotalProd) + parseInt(this.value.effectifTotal),
    };
    console.log("create");
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", { ...this.value, ...this.editableItem });
        // this.editableItem = { ...this.value };
      },
      deep: true,
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", { ...this.value, ...this.editableItem });
      console.log("dans handl");
      console.log(this.value);
    },
    fileSavedToitelle(files) {
      this.editableItem = {
        ...this.editableItem,
        imgToilette: files[0],
      };
    },
    fileSavedEau(files) {
      this.editableItem = {
        ...this.editableItem,
        imgEauPotable: files[0],
      };
    },
    fileSavedLavage(files) {
      this.editableItem = {
        ...this.editableItem,
        imgLavage: files[0],
      };
    },
    fileSavedSecurite(files) {
      this.editableItem = {
        ...this.editableItem,
        imgSecurite: files[0],
      };
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // eslint-disable-next-line no-unused-vars
    calculFemme(tot, hom, fem) {
      this.fem = tot - hom;

      console.log(hom, tot, this.fem);
    },
  },
};
</script>

<style scoped></style>
