<template>
  <div class="container-fluid mb-5">
    <div class="container- mt-1 mb-5">
      <div class="card border-0 mb-2">
        <DStepper
          :steps="steps"
          :activeStepIndex="activeStepIndex"
          :readonly="false"
          class=""
        />
      </div>
      <!-- "AUDIT" -->
      <div v-if="activeStepIndex == 0">
        <PCard class="shadow-none">
          <template #subtitle>
            <div class="d- justify-content-between px-4">
              <div>
                <div class="mb-2">
                  <!-- <AuditList v-model="entreprise.audit" :entreprise="entreprise" /> -->
                  <FicheRapportEnvironnemental
                    :entrepriseId="entreprise.id"
                    :value="entreprise"
                  />
                </div>
              </div>
            </div>
          </template>
        </PCard>
        <PDivider />
      </div>
      <!-- "CONSTAT" -->
      <div v-if="activeStepIndex == 1">
        <PCard class="shadow-none">
          <template #title>
            <div class="d-flex justify-content-between">
              <!-- <span>Constat</span> -->
              <!-- <a class="btn btn-light" href=""><i class="pi pi-caret-right"></i> EVADA</a> -->
              <!-- {{ activeStepIndex }} -->
            </div>
          </template>
          <template #subtitle>
            <div class="d- justify-content-between px-4">
              <div>
                <div class="mb-2">
                  <ConstatList :entreprise="entreprise" />
                </div>
              </div>
            </div>
          </template>
        </PCard>
        <PDivider />
      </div>
      <!-- "POINT FORT" -->
      <div v-if="activeStepIndex == 2">
        <AtoutsList :entrepriseId="entrepriseId" />
      </div>
    </div>
  </div>
</template>

<script>
import DStepper from "../../../components/common/DStepper.vue";
import AuditList from "./AuditList.vue";
import ConstatList from "./ConstatList.vue";
import AtoutsList from "./AtoutsList.vue";
import DomaineAnalyse from "../../../components/espace/entreprise/audit/DomaineAnalyse.vue";
import { mapActions, mapGetters } from "vuex";
import FicheRapportEnvironnemental from "./FicheRapportEnvironnemental.vue";
import AuteurAuditList from "./AuteurAuditList.vue";

export default {
  props: {
    entrepriseId: {
      type: [Number, String],
      required: true,
    },
    entrepriseOb: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      entreprise: { ...this.entrepriseOb },
      activeStepIndex: 0,
      domaine: {},
      constat: {},
      self: false,
      self1: false,
    };
  },
  components: {
    DStepper,
    DomaineAnalyse,
    AuditList,
    ConstatList,
    FicheRapportEnvironnemental,
    AtoutsList,
    AuteurAuditList,
  },
  computed: {
    ...mapGetters({
      audits: "audit/audits",
      constats: "constat/constats",
    }),
    steps() {
      return [
        {
          label: "MISSION D'EVALUATION",
          subtitle: "Information sur les audits",
          to: "#missionEvaluation",
          completed: true,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          label: "CONSTATS",
          subtitle: "Ajout des constats",
          to: "#Constats",
          completed: true,
          command: () => {
            if (this.auditCompleted) {
              this.activeStepIndex = 1;
            }
          },
        },
        {
          label: "POINT(S) FORT(S)",
          subtitle: "Point(s) fort(s) de l'entreprise",
          to: "#Recap",
          completed: true,
          command: () => {
            this.activeStepIndex = 2;
            // if (this.positionnementCompleted) this.activeStepIndex = 2;
          },
        },
      ];
    },
    auditCompleted() {
      let c = this.audits.length;
      if (this.self) {
        this.activeStepIndex = 0;
        return true;
      } else {
        if (c >= 1) {
          this.activeStepIndex = 1;
          return true;
        }
      }
    },
    constatCompleted() {
      let c = this.constats.length;
      if (c >= 1) {
        this.activeStepIndex = 2;
        return true;
      }
      return false;
    },
    autonomieCompleted() {
      return (
        this.priseEnCharge &&
        this.priseEnCharge.commentaireDegreAutonomie != null &&
        this.requiredModulesCompleted
      );
    },
    positionnementCompleted() {
      return (
        this.autonomieCompleted &&
        this.priseEnCharge &&
        this.priseEnCharge.cheminEmploi != null
      );
    },
  },
  watch: {
    // entrepriseOb(){
    //   this.fetchEntrepriseAudits(this.entrepriseId)
    // }
  },
  created() {
    this.fetchEntrepriseAudits(this.entrepriseId);
    this.getEntrepriseConstat(this.entrepriseId);
  },

  methods: {
    ...mapActions({
      getEntrepriseConstat: "constat/getEntrepriseConstat",
      fetchEntrepriseAudits: "audit/fetchEntrepriseAudits",
    }),
  },
};
</script>
