<template>
  <div class="">
    <PDialog
      v-if="!inline"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '70vw' }"
    >
      <searchable-item-participant-selector
        :listTitle="title"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="participants"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      />
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
    <template v-else>
      <SearchableItemEntrepriseSelector
        :listTitle="title"
        :referentielName="referentielName"
        :tableFields="tableFields"
        v-model="participants"
        :extraQueryArgsParamBuilder="extraQueryArgsParamBuilder"
        :emptyReferentielItem="emptyReferentielItem"
      >
        <template #actions="{ data }">
          <slot name="selectorActions" :data="data">
            Pas d'action. slot selectorActions
          </slot>
        </template>
      </SearchableItemEntrepriseSelector>
    </template>
  </div>
</template>

<script>
import SearchableItemEntrepriseSelector from "../../common/SearchableItemEntrepriseSelector.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    inline: {
      type: Boolean,
      require: true, //If false then will function as a dialog
    },
    items: {
      type: Array,
      default: () => [],
    },
    referentielName: {
      type: String,
      default: "entrepriseSelector",
    },
    // showActions: {
    //   type:  Boolean,
    //   default: false
    // },
    fields: {
      type: Array,
      default: () => [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "phone", label: "Téléphone" },
        { key: "commune.nom", label: "Commune" },
      ],
    },
  },
  components: {
    SearchableItemEntrepriseSelector,
  },
  data() {
    return {
      display: false,
      participants: [],
    };
  },
  created() {},
  watch: {
    items() {
      this.participants = [...this.items];
    },
    participants() {
      this.$emit("change", this.participants);
    },
  },
  computed: {
    emptyReferentielItem() {
      return {
        titre: null,
        code: null,
      };
    },
    tableFields() {
      return this.fields;
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
  },
  methods: {
    extraQueryArgsParamBuilder() {
      return ``;
    },
    show() {
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      this.$emit("saved", this.editableItem);
      this.$emit("save", this.editableItem);
      this.hide();
    },
  },
};
</script>
