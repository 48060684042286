/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <PlanCompetenceDialog
      v-if="activePlanCompetence != null"
      ref="planCompetenceDialog"
      :item="activePlanCompetence"
      :hidePlacementField="entrepriseId != null"
      :title="'Ajout de présence au poste'"
      @save="savePlanCompetence"
    />
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(competenceMetier.type)="{ item }">
                  <PBadge
                    class="text-uppercase badge badge-primary"
                    :value="
                      item.competenceMetier.type == 'BASE'
                        ? 'COMPETENCE DE BASE'
                        : 'COMPETENCE DE SPECIFIQUE'
                    "
                    :severity="
                      item.competenceMetier.type == 'SPECIFIC' ? 'success' : 'primary'
                    "
                    v-if="item.competenceMetier.type"
                    >{{
                      item.competenceMetier.type == "BASE"
                        ? "COMPETENCE DE BASE"
                        : "COMPETENCE DE SPECIFIQUE"
                    }}
                  </PBadge>
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      v-tooltip.top="'Editer le plan pour cette compétence'"
                      @click="editerPlanCompetence(row.item)"
                    >
                      <i class="bi bi-pencil"></i>
                    </button>
                  </div>
                </template>
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import { authMixin } from "../../../../mixins/auth-mixin";

import PlanCompetenceDialog from "../../../../components/espace/entreprise/editor/PlanCompetenceDialog.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
    entrepriseId: {
      type: Number,
      default: null,
    },
  },
  components: {
    PlanCompetenceDialog,
  },
  mixins: [paginatorMixin, authMixin],
  data() {
    return {
      selected: [],
      search: "",
      activePriseDeService: {},
      activeCessationService: {},
      activePlanCompetence: {},
      planCompetence: null,
      dateDeb: null,
      dateFin: null,
      errorMessage: null,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      statusOptions: [
        { value: null, label: "Tous" },
        { value: "NON_DEMARRE", label: "NON_DEMARRE" },
        { value: "EN_COURS", label: "EN_COURS" },
        { value: "CLOTURE", label: "CLOTURE" },
        { value: "VALIDE", label: "VALIDE" },
      ],
      status: null,
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "planCompetences/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des points de présences",
                subtitle: "Liste des points de présences",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "planCompetences/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des points de présences.xlsx",
                },
              },
            });
          },
        },
      ],
      searchFields: ["intituleOffre"],
      searchFieldsOptions: [
        {
          label: "Référence placement",
          value: "referencePlacement",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Titre offre",
          value: "intituleOffre",
          visible: this.$can("filter.telephone", "Azoli"),
        },
      ],
      filterOptions: [],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "competenceMetier.libelle", label: "Compétence" },
        { key: "competenceMetier.type", label: "Type" },
        { key: "encadreur", label: "Encadreur" },
        { key: "duree", label: "Durrée" },
        { key: "actions", label: "Actions" },
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      createOrUpdatePlanCompetence: "planCompetence/createOrUpdatePlanCompetence",
      cloturerPlanCompetence: "planCompetence/cloturerPlanCompetence",
      genererPlanCompetences: "planCompetence/genererPlanCompetences",
    }),
    editerPlanCompetence(planCompetence) {
      this.activePlanCompetence = { ...planCompetence };
      if (this.activePlanCompetence != null) {
        this.activePlanCompetence.entreprise = { id: this.entrepriseId };
      }
      this.$refs.planCompetenceDialog.show();
    },
    savePlanCompetence(planCompetence) {
      this.createOrUpdatePlanCompetence(planCompetence)
        .then((data) => {
          this.$toast.success("Plan de compétence sauvegardé avec succès!", {
            position: "top-right",
            duration: 8000,
          });
        })
        .catch((err) => err);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "planCompetence",
        search: this.search,
        fetcherMethod: "planCompetence/fetchPlanCompetences",
        dataGetter: "planCompetence/planCompetences",
        paginationGetter: "planCompetence/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "competenceMetier.type",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=competenceMetier.type,asc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("status", this.status);

          let params = `${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style>
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
