<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <!-- <block-maitrise-langues title="" v-model="editableItem.maitriseLangues" :hideAlphabetisation="true" /> -->
        <div class="row">
          <h5>Effectifs permanents</h5>

          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanentHommeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanentHommeProd"
                  name="effectifPermanentHommeProd"
                  placeholder="Effectif permanent Homme"
                />
                <label for="effectifPermanentHomme">Homme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanentFemmeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanentFemmeProd"
                  name="effectifPermanentFemmeProd"
                  placeholder="Effectif permanent femme"
                />
                <label for="effectifPermanentFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifPermanentProd"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifPermanentProd"
                  name="effectifPermanentProd"
                  placeholder="Effectif total"
                  disabled
                />
                <label for="effectifPermanent">Total</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <h5>Effectifs temporaires</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaireHommeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaireHommeProd"
                  name="effectifTemporaireHommeProd"
                  placeholder="effectif temporaire homme"
                />
                <label for="effectifTemporaireHomme">Homme </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaireFemmeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaireFemmeProd"
                  name="effectifTemporaireFemmeProd"
                  placeholder="Effectif Temporaire femme"
                />
                <label for="effectifTemporaireFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTemporaireProd"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTemporaireProd"
                  name="effectifTemporaireProd"
                  placeholder="Effectif temporaire"
                  disabled
                />
                <label for="effectifTemporaire">Total</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <hr />
          <h5>Effectifs stagiaires</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaireHommeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaireHommeProd"
                  name="effectifStagiaireHommeProd"
                  placeholder="Homme"
                />
                <label for="effectifStagiaireHomme">Homme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>

          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaireFemmeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaireFemmeProd"
                  name="effectifStagiaireFemmeProd"
                  placeholder="Femme"
                />
                <label for="effectifStagiaireFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifStagiaireProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifStagiaireProd"
                  name="effectifStagiaireProd"
                  placeholder="Effectif stagiaire"
                  disabled
                />
                <label for="effectifStagiaire">Total </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <h5>Effectif total</h5>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalHommeProd"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotalHommeProd"
                  name="effectifTotalHommeProd"
                  placeholder="Effectif total homme"
                  disabled
                />
                <label for="effectifTotalHomme">Homme </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-6">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalFemmeProd"
                  @input="handleInput"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotalFemmeProd"
                  name="effectifTotalFemmeProd"
                  placeholder="Effectif total femme"
                  disabled
                />
                <label for="effectifTotalFemme">Femme</label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <div class="form-floating mb-3">
                <input
                  :value="editableItem.effectifTotalProd"
                  type="number"
                  v-uppercase
                  class="form-control"
                  id="effectifTotalProd"
                  name="effectifTotalProd"
                  placeholder="Effectif total"
                  disabled
                />
                <label for="effectifTotalFemme">Total </label>
                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </div>
            </ValidationProvider>
          </div>
          <hr />
          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Dispositif de formation personnel ?"
                v-model="editableItem.isFormationPersonnel"
              >
              </c-yes-or-no-input>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isFormationPersonnel">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="field-checkbox">
                  <PCheckbox
                    :binary="true"
                    id="Intrene"
                    name="Intrene"
                    value="Intrene"
                    v-model="editableItem.isFormationPersonnelInterne"
                  />
                  <label for="Intrene">Intrene</label>
                </div>
                <div class="field-checkbox">
                  <PCheckbox
                    :binary="true"
                    id="Externe"
                    name="Externe"
                    value="Externe"
                    v-model="editableItem.isFormationPersonnelExterne"
                  />
                  <label for="Externe">Externe</label>
                </div>

                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </ValidationProvider>
            </div>
            <br />
          </div>

          <div class="col-sm-12">
            <ValidationProvider rules="required" v-slot="{ errors }">
              <c-yes-or-no-input
                question="Dispositif de garderie d'enfant de bas âge du personnel ?"
                v-model="editableItem.isGarderiePersonnel"
              >
              </c-yes-or-no-input>
              <span
                v-for="(err, index) in errors"
                :key="index"
                class="text-danger"
                >{{ err }}</span
              >
            </ValidationProvider>
            <br />
            <div v-if="editableItem.isGarderiePersonnel">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="field-checkbox">
                  <PCheckbox
                    :binary="true"
                    id="Intrene1"
                    name="Intrene1"
                    value="Intrene1"
                    v-model="editableItem.isGarderiePersonnelInterne"
                  />
                  <label for="Intrene1">Intrene</label>
                </div>
                <div class="field-checkbox">
                  <PCheckbox
                    :binary="true"
                    id="Externe1"
                    name="Externe1"
                    value="Externe1"
                    v-model="editableItem.isGarderiePersonnelExterne"
                  />
                  <label for="Externe1">Externe</label>
                </div>

                <span
                  v-for="(err, index) in errors"
                  :key="index"
                  class="text-danger"
                  >{{ err }}</span
                >
              </ValidationProvider>
            </div>
          </div>
          <!-- {{ value }} -->
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import CYesOrNoInput from "../../../../CYesOrNoInput.vue";
// import BlockMaitriseLangues from '../BlockMaitriseLangues.vue'
import { mapGetters } from "vuex";
export default {
  props: ["value"],
  components: {
    CYesOrNoInput,
    // BlockMaitriseLangues
  },
  data() {
    return {
      hasNip: null,
      pieces: [],
      editableItem: { effectifPermanentFemme: 0, effectifPermanentHomme: 0 },
    };
  },
  computed: {
    effectifPermanentProd() {
      return parseInt(this.editableItem.effectifPermanentFemmeProd || 0) +
                      parseInt(this.editableItem.effectifPermanentHommeProd || 0)
    },
    effectifTemporaireProd() {
      return parseInt(this.editableItem.effectifTemporaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemmeProd || 0)
    },
    effectifStagiaireProd() {
      return parseInt(this.editableItem.effectifStagiaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifStagiaireFemmeProd || 0)
    },
    effectifTotalHommeProd() {
      return parseInt(this.editableItem.effectifStagiaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifTemporaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifPermanentHommeProd || 0)                  
    },
    effectifTotalFemmeProd() {
      return parseInt(this.editableItem.effectifStagiaireFemmeProd || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemmeProd || 0) +
                      parseInt(this.editableItem.effectifPermanentFemmeProd || 0)        
    },
    effectifTotalProd() {
      return parseInt(this.editableItem.effectifStagiaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifTemporaireHommeProd || 0) +
                      parseInt(this.editableItem.effectifPermanentHommeProd || 0) +

                      parseInt(this.editableItem.effectifStagiaireFemmeProd || 0) +
                      parseInt(this.editableItem.effectifTemporaireFemmeProd || 0) +
                      parseInt(this.editableItem.effectifPermanentFemmeProd || 0)    
    }
  },
  created() {
    this.editableItem = { ...this.value };
    console.log("create");
  },
  watch: {
    editableItem: {
      handler(val) {
        this.$emit("input", { ...this.value, ...this.editableItem });
      },
      deep: true,
    },
  },
  methods: {
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.calculerTotaux()
      this.$emit("input", { ...this.value, ...this.editableItem });
    },
    calculerTotaux() {
      this.editableItem = {
        ...this.editableItem,
        effectifPermanentProd: this.effectifPermanentProd,
        effectifTemporaireProd: this.effectifTemporaireProd,
        effectifStagiaireProd: this.effectifStagiaireProd,
        effectifTotalHommeProd: this.effectifTotalHommeProd,
        effectifTotalFemmeProd: this.effectifTotalFemmeProd,
        effectifTotalProd: this.effectifTotalProd,
      }
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // eslint-disable-next-line no-unused-vars
    calculFemme(tot, hom, fem) {
      this.fem = tot - hom;

      console.log(hom, tot, this.fem);
    },
  },
};
</script>

<style scoped></style>
