/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box" v-if="!hideStatistic">
      <PriseEnChargeStatistic />
    </div>
    <CDetailViewer
      v-if="activeDemandeur"
      title="Fichier/CV du DE"
      ref="detailViewer"
      with="90wv"
      :item="activeDemandeur"
    >
      <PTabView :activeIndex.sync="activeTab">
        <PTabPanel header="Prise En Charge">
          <PriseEnCharge :demandeurId="activeDemandeur.id" />
        </PTabPanel>
        <PTabPanel header="CV">
          <DemandeurMonCv :checkHasCv="false" :demandeurId="activeDemandeur.id" />
        </PTabPanel>
        <PTabPanel header="Fiche d'enquête">
          <FicheAzoli v-if="activeDemandeur.estPPI" :demandeurId="activeDemandeur.id" />
          <div v-else>Ce demandeur n'a pas de fiche d'enquête</div>
        </PTabPanel>
      </PTabView>
    </CDetailViewer>
    <div class="container- mt-1">
      <template v-if="!hideHeader">
        <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
        <div class="bg-white p-2 rounded-4">
          <form class="bg-" method="post" accept-charset="utf-8" action="">
            <div class="row py-0 p-fluid grid formgrid">
              <div class="col field">
                <form class="d-flex">
                  <div class="input-group me-4">
                    <div class="p-inputgroup">
                      <!-- <span class="p-input-icon-left">
                      <i class="pi pi-search" />
                    </span> -->
                      <PInputText
                        type="text"
                        v-model="mxSearch"
                        :placeholder="'Rechercher par'"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 col-sm-12 d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <p-dropdown
                      class="flex-grow- me-2"
                      v-model="sexeDe"
                      :options="sexeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Sexe DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="demandeurType"
                      :options="demandeurTypeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type de DE'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutProfessionnel"
                      :options="statutProfessionnelOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut professionnel'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="autonomieDe"
                      :options="autonomieDeOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Autonomie'"
                      display="chip"
                    />
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="cheminEmploi"
                      :options="cheminEmploiOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Positionnement'"
                      display="chip"
                    />
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="d-grid gap-2">
                  <a
                    href="#"
                    @click.prevent="mxSubmitQuery"
                    class="style_btn btn btn-primary"
                    id="button-query"
                    ><i class="bi bi-search"></i
                  ></a>
                </div>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                  class="style_btn btn btn-warning"
                  id="button-query"
                >
                  <i class="bi bi-filter"></i>
                </button>
              </div>
            </div>
          </form>
          <transition>
            <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
              <div class="row p-fluid p-3 grid">
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de début</label>
                  <p-calendar
                    placeholder="Date de début"
                    v-model="dateDeb"
                    locale="fr"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <div class="field col-12 col-md-3">
                  <label for="basic">Date de fin</label>
                  <p-calendar
                    placeholder="Date de fin"
                    class="flex-grow-1"
                    v-model="dateFin"
                    :showIcon="true"
                    :manualInput="false"
                  />
                </div>
                <template v-for="(option, index) in filterOptions">
                  <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                    <label for="basic">{{ option.label }}</label>
                    <c-auto-complete
                      v-if="option.url"
                      display="chip"
                      v-model="option.value"
                      chips
                      :option="option"
                      :force-selection="true"
                    />
                    <p-multi-select
                      v-else-if="option.items && option.items.length != 0"
                      v-model="option.value"
                      :options="option.items"
                      :optionLabel="option.key"
                      :placeholder="option.label"
                      display="chip"
                    />
                    <p-input-text
                      v-else
                      v-model="option.value"
                      :placeholder="option.label"
                    />
                  </div>
                </template>
              </div>
            </div>
          </transition>
        </div>
        <p-divider />
        <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
          <div class="my-1 flex-fill">
            <h4>Liste des prises en charge</h4>
          </div>
          <div v-if="$can('create', 'Azoli')" class="btn-group">
            <!-- <a
              @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
              href=""
              class="style_btn btn btn-warning"
              >Programmer une activité</a
            > -->
          </div>
          <div class="dropdown">
            <button
              v-if="$can('transfer', 'FinOc')"
              class="btn ms-4 style_btn btn-primary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Affectation
            </button>
            <ul
              class="dropdown-menu dropdown-menu-end py-0"
              aria-labelledby="dropdownMenuButton1"
              style="margin: 0px"
            >
              <li v-if="$can('transferToAgent', 'FinOc')" class="border">
                <conseiller-selector
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="conseillerSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter à des conseillers</a
                    >
                  </template>
                </conseiller-selector>
              </li>
              <li v-if="$can('transferToAntenne', 'FinOc')" class="border">
                <antenne-selector-dialog
                  size="lg"
                  title=""
                  @saved="transfertDemandeur"
                  ref="antenneSelector"
                >
                  <template #action="{ show }">
                    <a class="dropdown-item" @click="show" href="#"
                      >Affecter vers une antenne</a
                    >
                  </template>
                </antenne-selector-dialog>
                <!-- <a class="dropdown-item" href="#">Affecter vers une antenne</a> -->
              </li>
            </ul>
          </div>
          <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
            <PSplitButton
              label="Exporter"
              :model="exportActions"
              class="p-button-outlined p-button-primary"
            />
          </div>
          <div class="btn-group ms-4">
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Affichage mode tableau"
              @click.prevent="viewMode = 'card'"
              class="btn style_btn btn-md"
              :class="{
                'btn-success': viewMode == 'card',
                'btn-light': viewMode == 'table',
              }"
            >
              <i class="bi-grid" />
            </button>
            <button
              @click.prevent="viewMode = 'table'"
              class="btn style_btn"
              :class="{
                'btn-light': viewMode == 'card',
                'btn-success': viewMode == 'table',
              }"
            >
              <i class="bi-table" />
            </button>
          </div>
        </div>
      </template>

      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="row mb-4" v-if="viewMode == 'card'">
            <div
              class="col-sm-4 col-lg-3 mb-3"
              v-for="pec in mxFilteredItems"
              :key="pec.id"
            >
              <div class="style_profil_card p-3 pt-3">
                <div class="d-flex mb-2">
                  <a href="#">
                    <div
                      @click="uploadFile(de)"
                      class="rounded-circle style_bg_img border"
                      :style="`background-image: url(${
                        pec.demandeur.fullPhotoUrl != null
                          ? pec.demandeur.fullPhotoUrl
                          : '/assets/img/profilMan.jpg'
                      }); height: 40px; width: 40px;`"
                    ></div>
                  </a>
                  <div
                    class="d-flex flex-column justify-content-start align-items-start mx-3"
                  >
                    <small
                      v-if="pec.demandeur"
                      class="text-dark text-truncate w-100 text-uppercase text-bold"
                      >{{ pec.demandeur.nom[0] }}. {{ pec.demandeur.prenom }}
                    </small>
                    <small v-if="pec.demandeur" class="text-muted text-uppercase"
                      ><span class="text-uppercase">{{
                        pec.validationDate | formatDate
                      }}</span></small
                    >
                  </div>
                </div>
                <div class="classement text-start mt-2 mb-3">
                  <div class="text-uppercase text-muted">PROFIL</div>
                  <PBadge
                    v-if="pec.classement"
                    :value="pec.classement.libelle"
                    class="ba"
                  ></PBadge>
                  <PBadge v-else value="N/A" severity="info"></PBadge>
                </div>
                <div class="positionnement mb-3 text-start">
                  <div class="my-1 text-uppercase text-muted">POSITIONNEMENT</div>
                  <span v-if="pec.cheminEmploi" class="text-dark">
                    {{ pec.cheminEmploi }}</span
                  >
                  <span v-else class="text-dark">{{ "N/A" }}</span>
                </div>
                <div class="positionnement mb-3 text-start">
                  <div class="my-1 text-uppercase text-muted">TAUX DE COMPLETION</div>
                  <PProgressBar
                    :value="computeProgression(pec)"
                    class="bg-"
                    style="height: 0.33em"
                  ></PProgressBar>
                  <div class="progress" style="height: 5px">
                    <div
                      class="progress-bar"
                      :class="{ 'bg-info': computeProgression(pec) }"
                      role="progressbar"
                      :style="`width: ${computeProgression(pec)}%;`"
                      :aria-valuenow="computeProgression(pec)"
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></div>
                  </div>
                </div>

                <div class="d-flex border-top pt-3 justify-content-center mt-2">
                  <!-- <a class="btn btn-success rounded-0 btn-sm me-1" ><i class="bi bi-pencil-square"></i></a> -->
                  <a
                    class="btn btn-info rounded btn-sm me-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="bottom"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(pec.demandeur)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                  <a
                    class="btn btn-danger rounded btn-sm me-1"
                    title="Générer PDF"
                    @click.stop.prevent="downloadFichePriseEncharge(pec.demandeur)"
                    ><i class="bi bi-printer"></i
                  ></a>
                  <!-- <a
                    @click.stop.prevent="remplirFiche(de)"
                    title="Saisir la fiche"
                    class="btn btn-warning rounded-0 btn-sm me-1"
                    ><i class="bi bi-file-earmark-check"></i
                  ></a> -->
                  <!-- <button
                    title="Valider la fiche me-1"
                    @click.prevent="validerFiche(de)"
                    class="btn btn-light btn-sm"
                  >
                    <i
                      :class="{ 'text-danger': de.status == 'VALIDE' }"
                      class="bi bi-caret-left-fill"
                    ></i>
                  </button> -->
                  <!-- <button
                    title="Prise en charge me-1"
                    @click.prevent="priseEnCharge(pec)"
                    class="btn btn-primary btn-sm"
                  >
                    <i class="bi bi-file-earmark-medical"></i>
                  </button> -->
                </div>
              </div>
            </div>
            <div class="col-sm-12">
              <div
                class="d-flex flex-wrap justify-content-center align-items-center mt-3"
              >
                <b-pagination
                  v-model="mxPagination.page"
                  :total-rows="mxTotalCount"
                  :per-page="mxPagination.rowsPerPage"
                  aria-controls="my-table"
                ></b-pagination>
              </div>
            </div>
          </div>
          <div v-else class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(id)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">PEC000{{ pec.id }}</div>
                </template>
                <template #cell(typeDemandeur)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <azoli-status-badge :de="pec.demandeur" />
                  </div>
                </template>
                <template #cell(demandeur)="{ item: pec }">
                  <span v-if="pec && pec.demandeur" class="text-uppercase"
                    >{{ pec.demandeur.nom }} {{ pec.demandeur.prenom }}</span
                  >
                </template>
                <template #cell(classement)="{ item: pec }">
                  <span v-if="pec && pec.classement" class="text-uppercase">{{
                    pec.classement.libelle
                  }}</span>
                </template>
                <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template>

                <template #cell(actions)="{ item: pec }">
                  <div class="d-flex justify-content-end">
                    <!-- <a class="btn btn-success rounded-0 btn-sm me-1" title
                      ><i class="bi bi-pencil-square"></i
                    ></a> -->
                    <a
                      class="btn btn-info rounded btn-sm me-1"
                      title="Consulter et modifier"
                      @click.stop="showDemandeur(pec.demandeur)"
                      ><i class="bi bi-info-square"></i
                    ></a>
                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1"><i class="bi bi-trash"></i></a> -->
                    <a
                      class="btn btn-danger rounded btn-sm me-1"
                      title="Générer PDF"
                      @click.stop.prevent="downloadFiche(pec.demandeur)"
                      ><i class="bi bi-printer"></i
                    ></a>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div class="d-flex flex- mb-2 align-items-center justify--center">
                  <PSkeleton shape="circle" size="4rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                    <PSkeleton width="12rem" class="mb-4"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import PriseEnChargeStatistic from "../../../components/statistic/pec/PriseEnChargeStatistic.vue";
import CDetailViewer from "../../../components/common/CDetailViewer.vue";
import PriseEnCharge from "./PriseEnCharge.vue";
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import DemandeurMonCv from "../demandeur/DemandeurMonCv.vue";
import FicheAzoli from "../conseiller/DemandeurDetailAzoli.vue";

import ConseillerSelector from "../../../components/espace/antenne/transfert/ConseillerSelector.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'

export default {
  props: {
    agentId: {
      type: Number,
      default: () => null,
    },
    extraQueryArg: {
      type: String,
      default: "",
    },
    hideStatistic: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    PriseEnChargeStatistic,
    ConseillerSelector,
    AntenneSelectorDialog,
    AzoliStatusBadge,
    CAutoComplete,
    ActiviteAnpeList,
    PriseEnCharge,
    CDetailViewer,
    DemandeurMonCv,
    FicheAzoli,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeDemandeur: {},
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      exportActions: [
        {
          label: "PDF",
          icon: "pi pi-file-pdf",
          command: () => {
            this.mxExportToPDF({
              meta: {
                title: "Liste des prises en charge",
                subtitle: "Liste des prises en charge",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "priseEnCharges/exportToPdf",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des prises en charge.pdf",
                },
              },
            });
          },
        },
        {
          label: "Excel",
          icon: "pi  pi-file-excel",
          command: () => {
            this.mxExportToExcel({
              meta: {
                title: "Liste des prises en charge",
                subtitle: "Liste des prises en charge",
              },
              pathParams: {
                name: "common.export",
                query: {
                  apiPath: "priseEnCharges/exportToExcel",
                  queryParams: this.mxGetQueryBuilder(),
                  fileName: "Liste des prises en charge.xlsx",
                },
              },
            });
          },
        },
      ],
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      autonomieDeOptions: [
        { value: null, label: "Tous" },
        { value: "DENA", label: "DENA" },
        { value: "DESA", label: "DESA" },
        { value: "DEA", label: "DEA" },
      ],
      autonomieDe: null,
      cheminEmploiOptions: [
        { value: null, label: "Tous" },
        { value: "SALARIE", label: "SALARIE" },
        { value: "INDEPENDANT", label: "INDEPENDANT" },
      ],
      cheminEmploi: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      statutProfessionnelOptions: [
        { value: null, label: "Tous" },
        { value: "CHOMEUR", label: "CHOMEUR" },
        { value: "OCCUPE", label: "OCCUPE" },
        { value: "PRIMO_DEMANDEUR", label: "PRIMO-DEMANDEUR" },
      ],
      // demandeurVulnerabiliteOptions: [
      //   { value: null, label: "Tous" },
      //   { value: "VULNERABLE", label: "VULNERABLE" },
      //   { value: "NON_VULNERABLE", label: "NON VULNERABLE" },
      // ],
      // demandeurVulnerabilite: null,
      statutProfessionnel: null,
      searchFields: ["demandeur.nomPrenom"],
      filterOptions: [
        {
          column: "nomPrenom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville.nom",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      priseEnCharge: "priseEnCharge/priseEnCharge",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "demandeur.nom", label: "Nom" },
        { key: "demandeur.prenom", label: "Prénom" },
        { key: "demandeur.sexe", label: "Sexe" },
        { key: "typeDemandeur", label: "Type demandeur" },
        { key: "statutProfessionnel.libelle", label: "Statut professionnel" },
        { key: "classement", label: "Profile" },
        { key: "creePar", label: "Suivi par" },
        { key: "cheminEmploi", label: "Positionnement" },
        // { key: "structureEmbauche", label: "Embauché par" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchDemandeurs: "demandeur/fetchDemandeurs",
      createOrUpdateDemandeur: "demandeur/createOrUpdateDemandeur",
      deleteDemandeur: "demandeur/deleteDemandeur",
      downloadAzoli: "demandeur/downloadAzoli",
      fetchDossier: "demandeur/fetchDossier",
      validerDossier: "demandeur/validerDossier",
      transfererVersConseiller: "transfert/transfererVersConseiller",
    }),
    showDemandeur(demandeur) {
      this.activeDemandeur = { ...demandeur };
      this.$refs.detailViewer.show();
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, demandeurId: de.id },
      });
    },
    computeProgression(priseEnCharge) {
      let percentage = 0;

      if (priseEnCharge.classement) percentage = 25;

      if (priseEnCharge.statutProfessionnel) percentage = 50;

      if (priseEnCharge.cheminEmploi) percentage = 75;

      if (priseEnCharge.consignes.length > 0) percentage = 100;

      return percentage;
    },
    transfertDemandeur(agent) {
      console.log("Agent: ", agent);
      if (this.selected.length == 0) return;
      this.transfererVersConseiller({
        agent,
        demandeur: this.selected[0],
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "priseEnCharge",
        search: this.search,
        fetcherMethod: "priseEnCharge/fetchPriseEnCharges",
        dataGetter: "priseEnCharge/priseEnCharges",
        paginationGetter: "priseEnCharge/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 8,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("categorieDemandeur", this.demandeurType);
          paramPlus = this.mxAddQueryParam(
            "statutProfessionnel.code",
            this.statutProfessionnel,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("demandeur.sexe", this.sexeDe, paramPlus);
          paramPlus = this.mxAddQueryParam(
            "classement.code",
            this.autonomieDe,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("cheminEmploi", this.cheminEmploi, paramPlus);

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style></style>
