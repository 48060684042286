<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.libelle"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    id="libelle"
                    name="libelle"
                    placeholder="libelle"
                  />
                  <label for="libelle">Libelle</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="editableItem.quantite"
                    @input="handleInput"
                    type="number"
                    v-uppercase
                    class="form-control"
                    id="quantite"
                    name="quantite"
                    placeholder="Quantité"
                  />
                  <label for="quantite">Quantité</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div>



            <div class="col-md-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <PDropdown class="col-12" v-model="editableItem.typeEquipement" :options="types" optionLabel="name" placeholder="Type d'equipement" optionValue="code"/>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                  <!-- <label for="typeAction">Type d'actions</label> -->
                </div>
              </ValidationProvider>
            </div>

            <div class="col-sm-12">
              <label>Observation</label>
              <!-- <ValidationProvider rules="required" v-slot="{ errors }"> -->
                <div class="form-floating mb-3">
                  <PTextarea  class="col-12" v-model="editableItem.observation"
                   rows="5" cols="30"
                   v-uppercase 
                   id="observation"/>
                  <!-- <input
                    :value="editableItem.observation"
                    @input="handleInput"
                    type="text"
                    v-uppercase
                    class="form-control"
                    
                    name="observation"
                    placeholder="Observation"
                  /> -->
                  
                  <!-- <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  > -->
                </div>
              <!-- </ValidationProvider> -->
            </div>

            <!-- <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input
                    :value="
                      parseInt(editableItem.femme) + parseInt(editableItem.home)
                    "
                    @input="handleInput"
                    type="number"
                    v-uppercase
                    class="form-control"
                    id="total"
                    name="total"
                    placeholder="Besoin Total"
                    readonly
                  />
                  <label for="total">Besoin Total</label>
                  <span
                    v-for="(err, index) in errors"
                    :key="index"
                    class="text-danger"
                    >{{ err }}</span
                  >
                </div>
              </ValidationProvider>
            </div> -->
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    entreprise: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
  },
  mixins: [],
  components: {
    Modal,
    //  CSelect,
    MetierSelect,
  },
  data() {
    return {
      editableItem: {},
      types: [
        { code: "FORMATION", name: "FORMATION" },
        { code: "INDIVIDUELLE", name: "INDIVIDUELLE" },
        { code: "COLLECTIVE", name: "COLLECTIVE" },
      ],
    };
  },
  created() {
    // this.fetchEntrepriseAudits(this.entreprise.id);
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
      console.log(this.editableItem.femme);
    },
  },
  computed: {
    ...mapGetters({
      // romes: "rome/romes",
    }),

    total() {
      return this.editableItem.homme + this.editableItemfemme;
    },
  },
  methods: {
    ...mapActions({
      fetchEntrepriseAudits: "audit/fetchEntrepriseAudits",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
