<template>
  <div class="">
    <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
      <form ref="form" method="post" @submit.prevent="handleSubmit()">
        <div class="row">
          <ValidationProvider rules="" v-slot="{ errors }">
            <div class="col-sm-12">
              <!-- <h5>Besoin en personnel</h5> -->
              <block-besoin-personnels
                :hideNiveau="true"
                v-model="editableItem.besoinPersonnels"
                :entreprise="editableItem"
              />
              <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                err
              }}</span>
            </div>
          </ValidationProvider>
        </div>
        <!-- {{editableItem}} -->
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { setepperFormMixin } from "@/mixins/stepper-form-mixin";
import { mapGetters, mapActions } from "vuex";
import BlockBesoinPersonnels from "../BlockBesoinPersonnels.vue";

export default {
  components: { BlockBesoinPersonnels /* YesOrNoBadge */ },
  props: ["value"],
  mixins: [setepperFormMixin],
  data() {
    return {
      editableItem: this.value,
    };
  },
  computed: {
    ...mapGetters({
      instructions: "instruction/instructions",
      niveauAlphabetions: "instruction/niveauAlphabetions",
      romes: "rome/romes",
      qualificationProfessionnelles:
        "qualificationProfessionnelle/qualificationProfessionnelles",
      formationProfessionnelles: "formationProfessionnelle/formationProfessionnelles",
    }),
    // niveauInstructionEnquetes() {
    //   return this.editableItem.typeActivation != "CAS_SPECIAL"
    //     ? this.instructions
    //     : this.instructions.slice(0, 10);
    // },
    // estPeuOuPasInstruit() {
    //   return (
    //     this.estInstruitEnquete != null &&
    //     (!this.estInstruitEnquete ||
    //       (this.estInstruitEnquete &&
    //         this.editableItem.niveauInstructionEnquete != null &&
    //         this.editableItem.niveauInstructionEnquete <= 10))
    //   );
    // },
    //   hasQualificationProfessionnelle() {
    //     return this.editableItem.hasQualificationProfessionnelle === true;
    //   },
    //   hasFormationProfessionnelle() {
    //     return this.editableItem.hasFormationProfessionnelle === true;
    //   },
  },
  watch: {
    estInstruitEnquete() {
      this.editableItem.estInstruitEnquete = this.estInstruitEnquete;
      this.$emit("input", this.editableItem);
      if (this.estInstruitEnquete !== null && !this.estInstruitEnquete) {
        this.editableItem.niveauInstructionEnquete = null;
      }
    },
  },
  created() {
    this.fetchInstructions();
    this.fetchQualificationProfessionnelles();
    this.fetchFormationProfessionnelles();
    this.fetchRomes();
  },
  methods: {
    ...mapActions({
      fetchInstructions: "instruction/fetchInstructions",
      fetchRomes: "rome/fetchRomes",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
      fetchFormationProfessionnelles:
        "formationProfessionnelle/fetchFormationProfessionnelles",
    }),
    handleInput(e) {
      if (e.target.name === "niveauInstructionEnquete") {
        this.editableItem[e.target.name] = parseInt(e.target.value);
      } else {
        this.editableItem[e.target.name] = e.target.value;
      }

      this.$emit("input", this.editableItem);
    },
  },
};
</script>

<style scoped></style>
