<template>
  <div>
    <ImagePicker ref="imagePicker" @save="fileSaved" />
    <div class="d-flex justify-content-between">
      <h5 class="">Photo du constat</h5>
      <div class="d-flex">
        <PButton
          label="Fichier"
          class="mr-3"
          icon="pi pi-paperclip"
          @click.prevent="showImagePicker"
        />
        <PButton
          class="p-button-outlined"
          label="Camera"
          icon="pi pi-camera"
          @click.prevent="showImagePicker"
        />
      </div>
    </div>
    <!-- <PGalleria
      :value="photoConstats"
      :responsiveOptions="responsiveOptions"
      :numVisible="5"
      style="max-width: 640px"
    >
      <template #item="slotProps">
        <img
          :src="slotProps.item.fullPhotoUrl"
          :alt="'Photo url'"
          style="width: 100%; display: block"
        />
      </template>
      <template #thumbnail="slotProps">
        <img
          :src="slotProps.item.fullPhotoUrl"
          :alt="'Photo url'"
          tyle="width: 100%; display: block;"
        />
      </template>
    </PGalleria> -->
    <div v-if="photoConstats" class="row">
      <div v-for="(pc, index) of photoConstats" class="col-md-3" :key="index">
        <PImagePreview
          :src="pc.fullPhotoUrl"
          :alt="'Photo'"
          style="cursor: pointer"
          height="200px"
          class="rounded p-1"
          preview
          @click="imageClick(index)"
        />
        <div>{{ pc.libelle }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ImagePicker from "@/components/common/ImagePicker.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    constat: {
      type: Object,
      required: true,
    },
  },
  components: {
    ImagePicker,
  },
  data() {
    return {
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      images: [],
    };
  },
  created() {
    this.fetchPhotoConstatsByConstat({ constatId: this.constat.id });
  },
  computed: {
    ...mapGetters({
      photoConstats: "photoConstat/photoConstats",
    }),
  },
  methods: {
    ...mapActions({
      fetchPhotoConstatsByConstat: "photoConstat/fetchPhotoConstatsByConstat",
      createPhotoConstat: "photoConstat/createPhotoConstat",
    }),
    showImagePicker() {
      this.$refs.imagePicker.show();
    },
    fileSaved(fileInfo) {
      this.createPhotoConstat({
        photoUrl: fileInfo.fileUrl,
        libelle: fileInfo.fileName,
        constat: this.constat,
      });
    },
  },
};
</script>
