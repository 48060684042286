<template>
  <div>
    <slot name="action" :show="show">
      <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Ajouter</a>
      <!-- <b-button variant="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Créer</b-button> -->
    </slot>
    <!-- <PDialog v-if="!inline" :header="title" :modal="true" :visible.sync="display" :containerStyle="{width: '70vw', background: '#f8f9fa'}"> -->

    <PDialog
      class="stepper-dialog"
      :header="title"
      :modal="true"
      :visible.sync="display"
      :containerStyle="{ width: '70vw' }"
      :contentStyle="{ overflow: 'auto' }"
    >
      <template #header>
        <div class="card px-0 border-0 mb-2 w-100">
          <div>
            <h4 class="d-flex justify-content-between">
              {{ title }}
              <PButton
                @click="cancel()"
                icon="pi pi-times"
                class="p-button-rounded p-button-outlined p-button-danger"
              />
            </h4>
            <PDivider />
          </div>
          <DStepper
            class="shadow-light bg-primary"
            :steps="steps"
            :activeStepIndex="activeStepIndex"
            :readonly="false"
          />
          <div class="d-flex justify-content-between mt-2">
            <div class="px-2">
              Agent séléctionné:
              <PTag severity="danger">{{
                selectedProfile.id != null ? selectedProfile.nomPrenom : "Aucun"
              }}</PTag>
            </div>
            <div>
              Nombre d'entreprise séléctionnée(s):
              <PTag severity="danger">{{
                editableItem.dossiers != null ? editableItem.dossiers.length : 0
              }}</PTag>
            </div>
          </div>
        </div>
      </template>
      <div class="bg-white shadow-light rounded-3 bg-r p-3">
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <div class="card bg-light mt-4 border-0">
            <form ref="form" method="post" @submit.prevent="handleSubmit()">
              <CDetailViewer
                title="Dossier de l'entreprise"
                ref="detailViewer"
                :item="activeEntreprise"
                @close="activeEntreprise = null"
              >
                <PTabView :activeIndex.sync="activeTab">
                  <PTabPanel
                    :header="`Dossier de l'entreprise ${activeEntreprise.raisonSocial}`"
                  >
                    <EntrepriseDossierDetail :entrepriseId="activeEntreprise.id" />
                  </PTabPanel>
                  <PTabPanel header="Info">
                    <!-- <EntrepriseProfil :entrepriseId="activeEntreprise.id" /> -->
                    Autres informations
                  </PTabPanel>
                </PTabView>
              </CDetailViewer>
              <ParticipantEntrepriseSelectorDialog
                v-if="activeStepIndex == 1"
                :fields="participantSelectorFields"
                ref="participantSelector"
                :items="selectedEntreprises"
                @change="participantSelectionChanged"
                :title="'Selection de l\'entreprise'"
                :inline="true"
              >
                <template #selectorActions="{ data }">
                  <button
                    title="Voir dossier de l'entreprise"
                    class="btn btn-sm py-1 btn-outline-primary"
                    @click="showEntreprise(data.item)"
                  >
                    <i class="bi bi-info-square"></i>
                  </button>
                </template>
              </ParticipantEntrepriseSelectorDialog>
              <template v-if="activeStepIndex == 0">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="input-group me-4">
                      <input
                        v-model="mxSearch"
                        type="text"
                        placeholder="Rechercher un conseiller ou un ULP par nom ou par matricule"
                        aria-describedby="button-query"
                        class="form-control"
                      />
                      <button
                        type="submit"
                        id="button-query"
                        class="style_btn btn btn-primary"
                      >
                        <i class="bi bi-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 my-3 text-muted">
                  Cliquez sur le profil du conseiller qui doit suivre le dossier du(des)
                  agent(s) sélectionné(s).
                </div>
                <small v-if="error" class="text-danger"
                  >Vous devez sélectionner au moins un profil</small
                >
                <p-progress-bar
                  v-if="mxLoading"
                  style="height: 0.33em"
                  mode="indeterminate"
                />
                <div class="row mb-4" v-if="viewMode == 'card'">
                  <div
                    class="col-sm-6 col-lg-4 mb-3"
                    v-for="profile in mxFilteredItems"
                    :key="profile.id"
                  >
                    <div
                      :class="{
                        'border card-primary border-primary':
                          selectedProfile.id == profile.id,
                      }"
                      class="style_profil_card p-3"
                      @click="selectProfile(profile)"
                    >
                      <div class="d-flex">
                        <a href="#">
                          <div
                            class="rounded-circle style_bg_img border"
                            style="
                              background-image: url('/assets/img/profilMan.jpg');
                              height: 60px;
                              width: 60px;
                            "
                          ></div>
                        </a>
                        <div class="w-100 border-bottom">
                          <h6 class="my-1">
                            <a href="#" class="text-dark"
                              >{{ profile.nom }} {{ profile.prenom }}</a
                            >
                          </h6>
                          <div class="text-muted text-wrap" v-if="profile.fonction">
                            {{ profile.fonction.nom }}
                          </div>
                          <small class="text-muted text-wrap" v-if="profile.creePar">{{
                            profile.email
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div
                      class="d-flex flex-wrap justify-content-center align-items-center"
                    >
                      <b-pagination
                        v-model="mxPagination.page"
                        :total-rows="mxPageCount"
                        :per-page="mxPagination.rowsPerPage"
                        aria-controls="my-table"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
                <div v-if="!mxLoading && tableData.length == 0" class="text-h5">
                  Aucun élément trouvé
                </div>
              </template>
            </form>
          </div>
        </ValidationObserver>
      </div>
      <template #footer>
        <PButton
          label="Annuler"
          icon="pi pi-times"
          @click.prevent="cancel"
          class="p-button-text"
        />
        <PButton
          label="Valider"
          :disabled="activeStepIndex == 0"
          icon="pi pi-check"
          @click.prevent="save"
          autofocus
        />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { paginatorMixin } from "@/mixins/paginator-mixin";
import DStepper from "../../../../components/common/DStepper.vue";
import ParticipantEntrepriseSelectorDialog from "../../common/ParticipantEntrepriseSelectorDialog.vue";
import EntrepriseDossierDetail from "../../../../views/espace/entreprise/EntrepriseDossierDetail.vue";
import CDetailViewer from "../../../common/CDetailViewer.vue";

export default {
  components: {
    ParticipantEntrepriseSelectorDialog,
    DStepper,
    EntrepriseDossierDetail,
    CDetailViewer,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  data() {
    return {
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedProfile: {},
      selectedEntreprises: [],
      error: false,
      display: false,
      activeEntreprise: {},
      activeStepIndex: 0,
      activeTab: 0,
    };
  },
  watch: {
    item(val) {
      this.editableItem = { ...this.item };
      if (val.agent != null) {
        this.selectProfile(val.agent);
      }
    },
  },
  computed: {
    steps() {
      return [
        {
          label: "SELECTION DU CONSEILLER",
          subtitle: "Conseiller à qui envoyer le(s) entreprise(s)",
          to: "#",
          completed: this.selectedProfile != null && this.selectedProfile.id != null,
          command: () => {
            this.activeStepIndex = 0;
          },
        },
        {
          label: "SELECTION ENTREPRISE",
          subtitle: "Sélection du(s) entreprise(s)",
          to: "#",
          completed: this.positionnementCompleted,
          command: () => {
            if (this.autonomieCompleted) this.activeStepIndex = 1;
          },
        },
      ];
    },
    options() {
      return this.structures.map((item) => ({ value: item, text: item.libelle }));
    },
    participantSelectorFields() {
      return [
        "#",
        "index",
        { key: "raisonSocial", label: "Nom" },
        { key: "nomDirigeant", label: "Dirigeant" },
        { key: "phone", label: "Téléphone" },
        { key: "rccm", label: "RCCM" },
        { key: "adresseSiege", label: "Siège" },
        { key: "ifu", label: "IFU" },
        // { key: 'departement.nom', label: 'Département' },
        { key: "commune.nom", label: "Commune" },
        // { key: 'ville.nom', label: 'Village/Qtr' },
        { key: "estSuivi", label: "Statut" },
        { key: "createdDate", label: "Inscrit le" },
        { key: "actions", label: "Actions" },
      ];
    },
    tableFields() {
      return [
        "#",
        "index",
        { key: "nom", label: "Nom" },
        { key: "prenom", label: "Prénom" },
        { key: "sexe", label: "Sexe" },
        { key: "phone", label: "Téléphone" },
        { key: "estSuivi", label: "Statut" },
        { key: "departement.nom", label: "Département" },
        { key: "commune.nom", label: "Commune" },
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    resetContent() {
      this.activeStepIndex = 0;
      this.selectedProfile = {};
      this.error = false;
      this.selectedEntreprises = [];
      this.loadData();
    },
    loadData() {
      this.mxInitializePaginator({
        queryModel: "agent",
        search: this.search,
        fetcherMethod: "agent/fetchAntenneAgentConseillers",
        dataGetter: "agent/agents",
        paginationGetter: "agent/pagination",
        pagination: {
          sortBy: "desc",
          descending: false,
          page: 0,
          rowsPerPage: 6,
        },
        filterOptions: this.filterOptions,
        searchFields: ["search"],
        extraQueryBulder: () => {
          return ``;
        },
      });
    },
    selectProfile(profile) {
      this.error = false;
      this.selectedProfile = profile;
      this.activeStepIndex = 1;
    },
    participantSelectionChanged(items) {
      this.editableItem = {
        dossiers: items,
      };
    },
    showEntreprise(entreprise) {
      this.activeEntreprise = { ...entreprise };
      this.$refs.detailViewer.show();
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    ok() {
      this.$emit("saved", this.selectedProfile);
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;

      if (this.selectedProfile.id == null) {
        this.error = true;
        return;
      }

      this.$emit("saved", {
        ...this.editableItem,
        agent: this.selectedProfile,
        // dossiers: this.selectedEntreprises
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>

<style>
.field-checkbox,
.field-radiobutton {
  display: flex;
  justify-items: center;
}
.stepper-dialog .p-dialog-header-icons {
  display: none;
}

.stepper-dialog .p-dialog-content {
  background: #e1e4e1;
}

.stepper-dialog .p-dialog-footer {
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.2), 0px -1px 1px rgba(0, 0, 0, 0.2);
}

.stepper-dialog .p-dialog-header {
  box-shadow: 1px 2px 0px 0px rgba(0, 0, 0, 0.5);
}

.shadow-light {
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 3%) !important;
}
</style>
