<template>
  <div>
    <PDialog
      :header="'Information sur la date publication'"
      :modal="true"
      :visible.sync="display"
      :contentStyle="{ overflow: 'visible' }"
      :containerStyle="{ width: '30vw' }"
    >
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <div class="card mt border-0">
          <form ref="form" method="post" @submit.prevent="handleSubmit()">
            <template>
              <div class="row">
                <div class="col-sm-12">
                  <label>Observation</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <div class="col-sm-12 mb-sm-12">
                        <p-editor
                          id="observation"
                          v-model="editableItem.observation"
                          editorStyle="height: 220px"
                        ></p-editor>
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-12">
                  <label>Justification</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <div class="col-sm-12 mb-sm-12">
                        <textarea
                          v-model="editableItem.justification"
                          type="text"
                          class="form-control"
                          id="justification"
                          placeholder="justification"
                          height="50px"
                        />
                      </div>
                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <label>Date debut candidature</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <p-calendar
                        class="col-12 md:col-12"
                        :showIcon="true"
                        size="sm"
                        name="dateEff"
                        :manualInput="false"
                        dateFormat="dd/mm/yy"
                        :showTime="true"
                        v-model="editableItem.dateEff"
                        :showWeek="true"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <label>Date de clôture candidature</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <p-calendar
                        class="col-12 md:col-12"
                        :showIcon="true"
                        size="sm"
                        name="dateCloture"
                        :manualInput="false"
                        dateFormat="dd/mm/yy"
                        :showTime="true"
                        v-model="editableItem.dateCloture"
                        :showWeek="true"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-6">
                  <label>Date debut publication</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <p-calendar
                        class="col-12 md:col-12"
                        :showIcon="true"
                        size="sm"
                        name="dateDebutPub"
                        :manualInput="false"
                        :showTime="true"
                        dateFormat="dd/mm/yy"
                        v-model="editableItem.dateDebutPub"
                        :showWeek="true"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
                <div class="col-sm-6">
                  <label>Date fin publication</label>
                  <ValidationProvider rules="required" v-slot="{ errors }">
                    <div class="form-floating mb-3">
                      <p-calendar
                        class="col-12 md:col-12"
                        :showIcon="true"
                        size="sm"
                        name="dateFinPub"
                        dateFormat="dd/mm/yy"
                        :showTime="true"
                        :manualInput="false"
                        v-model="editableItem.dateCloture"
                        :showWeek="true"
                      />

                      <span
                        v-for="(err, index) in errors"
                        :key="index"
                        class="text-danger"
                        >{{ err }}</span
                      >
                    </div>
                  </ValidationProvider>
                </div>
              </div>
            </template>
          </form>
        </div>
      </ValidationObserver>
      <template #footer>
        <PButton
          label="Quitter"
          icon="pi pi-times"
          @click="cancel"
          class="p-button-text"
        />
        <PButton label="Valider" icon="pi pi-check" @click="save" autofocus />
      </template>
    </PDialog>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

import { paginatorMixin } from "@/mixins/paginator-mixin";
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: "md",
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  mixins: [paginatorMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      display: false,
      editableItem: {},
      search: "",
      viewMode: "card", //card, table
      filterOptions: [],
      selectedDemandeurs: [],
      error: false,
      activeStepIndex: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.editableItem = { ...this.item };
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    resetContent() {
      this.editableItem = {};
    },
    show() {
      this.resetContent();
      this.display = true;
    },
    hide() {
      this.display = false;
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    async save() {
      if (!(await this.isCompleted())) return;
      this.$emit("save", {
        ...this.editableItem,
        // dossiers: this.selectedDemandeurs
      });
      this.editableItem = {};
      this.hide();
    },
  },
};
</script>
