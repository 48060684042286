<template>
  <main>
    <div class="container">
      <PMessage
        severity="warn"
        v-if="
          isEntreprise &&
          !editMode &&
          entreprise.tauxRemplissageProfil != null &&
          entreprise.tauxRemplissageProfil < 10
        "
        >Vous n'avez pas encore constitué votre dossier.
        <p-button class="p-button p-button-sm ms-2" @click.prevent="editMode = true"
          >Constituer mon dossier</p-button
        ></PMessage
      >
      <EntrepriseDossierDetail
        class="mt-5"
        v-if="showDetail"
        @edit="editMode = true"
        :entrepriseId="entreprise.id"
      />
      <div v-else class="col-sm-8 mx-auto py-5">
        <div class="bg-white p-4 rounded-lg shadow-md">
          <div class="row">
            <div class="col-sm-12">
              <div class="d-flex justify-content-center align-items-center mb-4">
                <div
                  class="bg-primary style_level d-flex justify-content-center align-items-center"
                >
                  <h1 class="display-4 mb-0 text-white">
                    {{ currentStep + 1
                    }}<span style="font-size: 25px">/{{ totalSteps }}</span>
                  </h1>
                </div>
              </div>
              <div class="text-center mb-4">
                <h4 class="text-primary">Constitution du dossier d'entreprise</h4>
                <!-- <h6 class="text-muted">Je suis une <a href="inscription_2.html" class="badge bg-success text-white">Entreprise</a></h6> -->
                <h6 class="text-muted">
                  Etape {{ currentStep + 1 }}/{{ steps[currentStep].title }}
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div v-for="(step, index) in steps" :key="index">
                <component
                  v-model="entreprise"
                  v-if="index == currentStep && step.visible"
                  :ref="`currentTab_${index}`"
                  :is="step.component"
                />
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <b-alert variant="danger" :show="error">
                    {{
                      `Le numéro de téléphone '${this.entreprise.phone}' existe déjà. Essayez avec un autre numéro de téléphone.`
                    }}
                  </b-alert>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isFirstStep"
                      @click.prevent="previousStep"
                      type="button"
                      class="btn btn-outline-primary btn-lg"
                    >
                      Précédent
                    </button>
                  </div>
                </div>
                <div class="col">
                  <div class="d-grid gap-2 my-4">
                    <button
                      v-if="!isLastStep"
                      @click.prevent="nextStep"
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Suivant
                    </button>
                    <button
                      @click.prevent="submitForm"
                      v-else
                      type="submit"
                      class="btn btn-primary btn-lg"
                    >
                      Valider
                    </button>
                  </div>
                </div>
              </div>
              <!-- <h6 class="text-muted text-center">J'ai un compte candidat <a href="connexion.html" class="text-primary">connectez-vous</a></h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import entrepriseDetail from "../../../components/espace/entreprise/fiche/steps/EntrepriseDetail.vue";
import PersonnelFormation from "../../../components/espace/entreprise/fiche/steps/PersonnelFormation.vue";
import PersonnelProduction from "../../../components/espace/entreprise/fiche/steps/PersonnelProduction.vue";
import SpecialistesEnvironnementaux from "../../../components/espace/entreprise/fiche/steps/SpecialistesEnvironnementaux.vue";
import Contacts from "../../../components/espace/entreprise/fiche/steps/Contacts.vue";
import Identification from "../../../components/espace/entreprise/fiche/steps/Identification.vue";
import Domicile from "../../../components/espace/entreprise/fiche/steps/Domicile.vue";
import ActiviteEntreprise from "../../../components/espace/entreprise/fiche/steps/ActiviteEntreprise.vue";
import BesoinPersonnel from "../../../components/espace/entreprise/fiche/steps/BesoinPersonnel.vue";
import CandidatureAzoli from "../../../components/espace/entreprise/fiche/steps/CandidatureAzoli.vue";
import EntrepriseDossierDetail from "./EntrepriseDossierDetail.vue";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { mapActions, mapMutations } from "vuex";
import { authMixin } from "../../../mixins/auth-mixin";

export default {
  props: ["entrepriseId"],
  components: {
    EntrepriseDossierDetail,
  },
  mixins: [authMixin],
  data() {
    return {
      currentStep: 0,
      entreprise: {
        identification: {},
        domicile: {},
        contacts: {},
        tauxRemplissageProfil: null,
        isCandidatAzoli: null,
        // activiteEntreprise: {},
        personnelFormation: {},
        besoinPersonnels: [],
        effectifPersonnels: [],
        profil: {},
        nom: null,
        prenom: null,
      },
      error: false,
      editMode: false,
    };
  },
  created() {
    if (!this.isEntreprise) {
      this.fetchDossier({
        entrepriseId: this.entrepriseId,
      })
        .then((result) => {
          this.entreprise = {
            ...this.entreprise,
            ...result,
          };
          this.editMode = this.$route.query.edit == true;
        })
        .catch((err) => {
          this.$toast.error(`Echec de chargement du dossier`, {
            position: "bottom-right",
            duration: 35000,
          });
        });
    } else {
      this.getProfile().then(() => {
        this.editMode = this.$route.query.edit == true;
      });
    }
  },
  watch: {
    entreprise() {
      // entre
      // localStorage.setItem("subscription", JSON.stringify(this.entreprise));
    },
    currentStep() {
      localStorage.setItem("currentStep", this.currentStep);
    },
    "entreprise.phone": {
      handler() {
        this.error = false;
      },
    },
  },
  computed: {
    tauxRemplissageProfil() {
      return this.entreprise.tauxRemplissageProfil || 0;
    },
    steps() {
      let steps = [
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Identification d'entreprise",
          component: Identification,
          visible: true,
        },
        {
          icon: "fa fa-shopping-cart",
          name: "second",
          title: "Contacts",
          component: Contacts,
          visible: true,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Activité de l'entreprise",
          component: ActiviteEntreprise,
          visible: true,
        },

        {
          icon: "fa fa-home",
          name: "first",
          title: "Besoin en personnel",
          component: BesoinPersonnel,
          visible: true,
        },

        {
          icon: "fa fa-home",
          name: "first",
          title: "Effectif du personnel de direction",
          component: PersonnelFormation,
          visible: this.entreprise.isCandidatAzoli == true,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Effectif du personnel de production",
          component: PersonnelProduction,
          visible: this.entreprise.isCandidatAzoli == true,
        },
        {
          icon: "fa fa-home",
          name: "first",
          title: "Specialistes Environnementaux",
          component: SpecialistesEnvironnementaux,
          visible: this.entreprise.isCandidatAzoli == true,
        },
      ];

      if (
        this.entreprise.isCandidatAzoli == null ||
        this.entreprise.tauxRemplissageProfil < 85
      ) {
        steps.unshift({
          icon: "fa fa-home",
          name: "first",
          title: "Candidature Azoli",
          component: CandidatureAzoli,
          visible: true,
        });
      }
      return steps;
    },
    totalSteps() {
      return this.steps.filter((step) => step.visible).length;
    },
    isEntreprise() {
      return this.$userRoleName.toUpperCase() === "ENTREPRISE";
    },
    isFirstStep() {
      return this.currentStep === 0;
    },
    isLastStep() {
      return this.currentStep === this.totalSteps - 1;
    },
    hiddeHeader() {
      return this.steps[this.currentStep].hiddeHeader;
    },
    hideFooter() {
      return this.steps[this.currentStep].hideFooter;
    },
    showDetail() {
      return this.editMode == false;
    },
  },
  methods: {
    ...mapActions({
      updateEntreprise: "entreprise/updateEntreprise",
      createOrUpdateEntreprise: "entreprise/createOrUpdateEntreprise",
      fetchDossier: "entreprise/fetchDossier",
      fetchMonProfil: "entreprise/fetchMonProfil",
      oneEntreprise: "entreprise/oneEntreprise",
    }),
    ...mapMutations({
      updateEntreprise: "entreprise/addEntreprise",
    }),
    getProfile() {
      return this.fetchMonProfil()
        .then((result) => {
          console.log(result);
          this.entreprise = {
            ...this.entreprise,
            ...result,
          };
          console.log(this.entreprise);
          return this.entreprise;
        })
        .catch((err) => {
          this.$toast.error(`Echec de chargement du dossier`, {
            position: "bottom-right",
            duration: 35000,
          });
        });
    },
    resetForm() {
      localStorage.removeItem("subscription");
      localStorage.removeItem("currentStep");
    },
    async submitForm() {
      if (await this.validateStep(this.currentStep)) {
        this.createOrUpdateEntreprise(this.entreprise)
          .then(() => {
            // const res = await this.$dialog.confirm({ text: `Vous-vous immédiatement procéder au remplissage de la fiche Azoli de ${de.nom} ${de.prenom}?` })
            this.$toast.success("Opération réussie!", {
              position: "bottom-right",
              duration: 5000,
            });
            // this.$router.push({ name: "espace.entreprise.home" });
            this.editMode = false;
          })
          .catch(() => {
            // this.error = true;
            this.$toast.error(`Echec d'ajout de dossier`, {
              position: "bottom-right",
              duration: 35000,
            });
          });
      }
    },
    previousStep() {
      if (this.isFirstStep) return;
      this.currentStep--;
    },
    async nextStep() {
      if (this.isLastStep) return;
      if (await this.validateStep(this.currentStep)) {
        this.currentStep++;
      }
    },
    async validateStep(step) {
      this.updateEntreprise(this.entreprise);
      let target = this.$refs[`currentTab_${step}`][0];
      return target.isCompleted();
    },
  },
};
</script>

<style>
.vue-form-wizard .wizard-progress-with-circle,
.vue-form-wizard .wizard-header,
.vue-form-wizard .wizard-nav.wizard-nav-pills {
  display: none;
}

.wizard-progress-with-circle {
  background: rgba(0, 0, 0, 0.2);
}

/* .vue-form-wizard .wizard-icon-circle .wizard-icon-container{
  }
  .vue-form-wizard .wizard-icon-circle{
  } */

/* .main-wizard {
    min-height: 60vh;
  } */
</style>
