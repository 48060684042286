<template>
  <modal :title="title" ref="modal" @save="save" :hide-footer="true">
    <template #default="{}">
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <div class="row">
            <!-- <div class="col-md-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="audit"
                    name="audit"
                    option-label="lieu"
                    option-value="id"
                    v-model="editableItem.audit"
                    :options="audits"
                    class="form-select"
                  >
                  </c-select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="audit">Audits</label>
                </div>
              </ValidationProvider>
            </div> -->

            <div class="col-md-12 col-md-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <c-select
                    id="domaineAnalyse"
                    name="domaineAnalyse"
                    option-label="libelle"
                    option-value="id"
                    v-model="editableItem.domaineAnalyse"
                    :options="domaineanalyses"
                    class="form-select"
                  >
                  </c-select>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                  <label for="domaineAnalyse">Critère</label>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <textarea
                    :value="editableItem.libelle"
                    @input="handleInput"
                    type="text"
                    rows="10"
                    height="100"
                    class="form-control"
                    id="libelle"
                    name="libelle"
                    style="height: 400px"
                    placeholder="Constat(s)"
                  />
                  <!-- <PTextarea
                    class="col-12"
                      id="libelle"
                      :value="editableItem.libelle"
                      rows="10"
                      
                    /> -->
                  <label for="libelle">Constat(s)</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{
                    err
                  }}</span>
                </div>
              </ValidationProvider>
            </div>
          </div>

          <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import { mapActions, mapGetters } from "vuex";
// import CSelect from '@/components/CSelect.vue'
import MetierSelect from "../../../../../common/MetierSelect.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      require: true,
    },
    hideNiveau: {
      type: Boolean,
      default: () => false,
    },
    // audits: {
    //   type: Array,
    //   default: () => {},
    // },
    entreprise: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [],
  components: {
    Modal,
    //  CSelect,
    MetierSelect,
  },
  data() {
    return {
      editableItem: {},
      qualification: [
        { code: "SQ", name: "Sans Qualification" },
        { code: "AQ", name: "Avec Qualification" },
      ],
    };
  },
  created() {
    this.getEntrepriseConstat(this.entreprise.id);
    this.fetchDomaineAnalyses();
    this.fetchEntrepriseAudits(this.entreprise.id);
  },
  watch: {
    item() {
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({
      audits: "audit/audits",
      domaineanalyses: "domaineanalyse/domaineanalyses",
    }),

    total() {
      return this.editableItem.homme + this.editableItemfemme;
    },
  },
  methods: {
    ...mapActions({
      getEntrepriseConstat: "constat/getEntrepriseConstat",
      fetchAudits: "audit/fetchAudits",
      fetchEntrepriseAudits: "audit/fetchEntrepriseAudits",
      fetchDomaineAnalyses: "domaineanalyse/fetchDomaineAnalyses",
    }),
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    show() {
      this.$refs.modal.show();
    },
    async save() {
      if (!(await this.isCompleted())) return;

      this.$emit("save", this.editableItem);
      this.close();
    },
    close() {
      this.$refs.modal.close();
    },
  },
};
</script>
