/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box">
      <activite-statistic ref="activiteStatistic" v-if="false" class="mt-3 mb-2" />
      <div class="stats-section mb-5 mt-3">
        <div class="row">
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Non affecté"
              :value="femmesAvecEnfantsCount"
            />
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Affecté à un conseillé"
              :value="femmeEnceinteCount"
            />
          </div>
          <div class="col-sm-6 col-lg mb-2">
            <azoli-value-box
              :showPercentage="true"
              :borderClass="'border-success border-start border-5'"
              label="Nombre total"
              :value="nombreEnfants3Mois3AnsCount"
            />
            <!-- <azoli-value-box /> -->
          </div>
        </div>
      </div>
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <PDivider class="pb-0 mb-" />
      <PButton
        rounded
        class="px-3 p-button-sm p-button-rounded custom-chip primary me-2"
        :class="{ 'active primary': currentViewMode == opt.value }"
        v-for="(opt, i) in viewModeOptions"
        :key="i"
        @click="currentViewMode = opt.value"
        :label="opt.name"
      />
      <PDivider class="pb-0 mt-2" />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Porte-feuille entreprise</h4>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group me-2">
          <EntrepriseTransfertDialog
            size="lg"
            :item="activeTransfert"
            title="Affecter des Entreprise à des CE/ULPE"
            @saved="transfertEntreprise"
            ref="conseillerSelector"
          >
            <template #action="{ show }">
              <a class="btn btn-warning" @click="showConseillerSelector(show)" href="#"
                >Affecter à des conseillers</a
              >
            </template>
          </EntrepriseTransfertDialog>
        </div>
        <div v-if="$can('create', 'Azoli')" class="btn-group">
          <AntenneEntrepriseSelectorDialog
            size="lg"
            title=""
            @saved="transfertEntrepriseVersAntenne"
            ref="antenneSelector"
          >
            <template #action="{ show }">
              <a class="btn btn-warning" @click="show" href="#"
                >Affecter vers une antenne</a
              >
            </template>
          </AntenneEntrepriseSelectorDialog>
        </div>
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
      </div>

      <template v-if="!mxLoading">
        <div class="bg-">
          <InscriptionIncompleteEntrepriseList
            v-if="currentViewMode == 'ALL_INSCRIPTION_IMCOMPLETE'"
            :hideStatistic="true"
            :hideHeader="true"
          />
          <EntrepriseList
            v-if="
              currentViewMode != 'ALL_CE' &&
              currentViewMode != 'ALL_INSCRIPTION_IMCOMPLETE'
            "
            :extraQueryArg="getExtraQueryArg"
            :hideStatistic="true"
            :hideHeader="true"
          />
          <div class="bg-white p-3 border" v-if="currentViewMode == 'ALL_CE'">
            <div class="table-responsive">
              <!-- <b-table :items="tableData" :fields="tableFields" striped responsive="sm">
                <template #cell(show_details)="row">
                  <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                    {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                  </b-button>

                  <b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
                    Details via check
                  </b-form-checkbox>
                </template>

                <template #row-details="row">
                  <b-card>
                    <b-row class="mb-2">
                      <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                      <b-col>{{ row }}</b-col>
                    </b-row>
                    <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                  </b-card>
                </template>
              </b-table> -->
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="single"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :sort-by.sync="mxPagination.sortBy"
                :sort-desc.sync="mxPagination.sortDesc"
                responsive="sm"
                sort-icon-left
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>

                <template #cell(id)="{ item: pec }">
                  <div class="d-flex justify-content- mb-3" v-if="pec">
                    PEC000{{ pec.id }}
                  </div>
                </template>
                <template #cell(typeEntreprise)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <azoli-status-badge :de="pec.entreprise" />
                  </div>
                </template>
                <template #cell(deCount)="{ item: pec }">
                  <div class="d-flex justify- mb-3" v-if="pec">
                    <span class="text-primary">{{ pec.deFCount + pec.deMCount }}</span>
                  </div>
                </template>
                <template #cell(entreprise)="{ item: pec }">
                  <span v-if="pec && pec.entreprise" class="text-uppercase"
                    >{{ pec.entreprise.nom }} {{ pec.entreprise.prenom }}</span
                  >
                </template>
                <template #cell(classement)="{ item: pec }">
                  <span v-if="pec && pec.classement" class="text-uppercase">{{
                    pec.classement.libelle
                  }}</span>
                </template>
                <template #cell(creePar)="{ item: de }">
                  <span class="text-uppercase" v-if="de.creePar"
                    >{{ de.creePar.nom }} {{ de.creePar.prenom }}</span
                  >
                </template>
                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-sm py-1 btn-outline-primary"
                      @click="mxToggleDetails(row, tableData)"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>

                <template #row-details="row">
                  <div class="bg- pa-2">
                    <PTabView :activeIndex.sync="activeTab">
                      <div class="d-flex justify-content-between mt-2 mb-2">
                        <h4>
                          Portefeuille du {{ row.item.agent.fonction.nom.split("_")[0] }}
                          <span class="text-primary"
                            >{{ row.item.agent.nom }} {{ row.item.agent.prenom }}</span
                          >
                        </h4>
                        <a
                          href="#"
                          @click.prevent="selectConseillerForTransfer(row.item.agent)"
                          class="btn btn-warning"
                          >Affecter des entreprises</a
                        >
                      </div>
                      <PTabPanel header="Placements"> Content III </PTabPanel>
                      <PTabPanel header="Statistiques"> Content III </PTabPanel>
                    </PTabView>
                  </div>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <PDataTable
          :value="
            [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
              e;
            })
          "
        >
          <PColumn v-for="f in tableFields" :key="f.key" :field="f.key" :header="f.label">
            <template #body>
              <PSkeleton></PSkeleton>
            </template>
          </PColumn>
        </PDataTable>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import ActiviteStatistic from "../../../components/statistic/activite/ActiviteStatistic.vue";

import EntrepriseTransfertDialog from "../../../components/espace/antenne/transfert/EntrepriseTransfertDialog.vue";
import AntenneEntrepriseSelectorDialog from "../../../components/espace/antenne/transfert/AntenneEntrepriseSelectorDialog.vue";
import AntenneSelectorDialog from "../../../components/espace/antenne/transfert/AntenneSelectorDialog.vue";
// import * as XLSX from 'xlsx'
import AzoliBeneficiaireList from "./AzoliBeneficiaireList.vue";
import EntrepriseList from "./EntrepriseList.vue";
import InscriptionIncompleteEntrepriseList from "./InscriptionIncompleteEntrepriseList.vue";
import ActiviteAnpeList from "./ActiviteAnpeList.vue";
import ConsigneList from "./ConsigneList.vue";
import PrescriptionList from "./PrescriptionList.vue";
import OffreList from "../entreprise/OffreList.vue";
// import ProgrammeList from './ProgrammeList.vue'
import PriseEnChargeList from "./PriseEnChargeList.vue";
import AzoliValueBox from "../../../components/statistic/AzoliValueBox.vue";

export default {
  components: {
    ActiviteStatistic,
    EntrepriseTransfertDialog,
    AntenneEntrepriseSelectorDialog,
    AntenneSelectorDialog,
    EntrepriseList,
    AzoliBeneficiaireList,
    InscriptionIncompleteEntrepriseList,
    PriseEnChargeList,
    ActiviteAnpeList,
    ConsigneList,
    OffreList,
    PrescriptionList,
    AzoliStatusBadge,
    CAutoComplete,
    AzoliValueBox,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      activeTransfert: null,
      showAdvancedFilter: false,
      activeTab: 0,
      currentViewMode: "ALL_CE", //card, table
      viewModeOptions: [
        { name: "Tous CE/ULPE", value: "ALL_CE" },
        { name: "Toutes entreprises", value: "ALL_DE" },
        { name: "Non affectées", value: "ALL_NON_AFFECTE" },
        { name: "Affectées", value: "ALL_AFFECTE" },
        { name: "Inscription incomplete", value: "ALL_INSCRIPTION_IMCOMPLETE" },
      ],
      exportActions: [
        // {
        // 	label: 'PDF',
        // 	icon: 'pi pi-file-pdf',
        // 	command: () => {
        //     const data = XLSX.utils.json_to_sheet(this.tableData.map(el => ({ ...el, ...el.azoli })))
        //     const wb = XLSX.utils.book_new()
        //     XLSX.utils.book_append_sheet(wb, data, 'data')
        //     XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
        // 	}
        // },
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["titre"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "titre",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Titre & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "titre",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: true,
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: true,
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: true,
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: true,
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: true,
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: true,
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Entreprises",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    currentViewMode() {
      localStorage.setItem("viewMode", this.currentViewMode);
    },
  },
  computed: {
    ...mapGetters({
      priseEnCharge: "entreprise/fetchEntreprises",
      entreprises: "entreprise/entreprises",
      pagination: "entreprise/pagination",
    }),
    getExtraQueryArg() {
      let queryArg = "";
      switch (this.currentViewMode) {
        case "ALL_DE":
          break;
        case "ALL_NON_AFFECTE":
          queryArg = `regroupements=NON_AFFECTE`;
          break;
        case "ALL_AFFECTE":
          queryArg = `regroupements=AFFECTE`;
          break;
        case "ALL_INSCRIPTION_IMCOMPLETE":
          break;
      }
      return queryArg;
    },
    emptyTransferItem() {
      return {
        dossiers: [],
        agent: null,
        antenne: null,
      };
    },
    tableFields() {
      //      private Long deCount = 0L;
      // private Long deFCount = 0L;
      // private Long deMCount = 0L;
      // private Long deDENACount = 0L;
      // private Long deDESACount = 0L;
      // private Long deDEACount = 0L;
      // private Long deIndependantCount = 0L;
      // private Long deSalarieCount = 0L;
      // private Long priseEnChargeCount = 0L;

      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "agent.nom", label: "Nom" },
        { key: "agent.prenom", label: "Prénom" },
        { key: "agent.sexe", label: "Sexe" },
        // { key: 'agent', label: 'Téléphone' },
        { key: "deCount", label: "NbDE" },
        { key: "deFCount", label: "NF" },
        { key: "deMCount", label: "NbM" },
        { key: "deDENACount", label: "NbDENA" },
        { key: "deDESACount", label: "NbDESA" },
        { key: "deDEACount", label: "NbDEA" },
        // { key: 'deSALARIECount', label: 'NbSALAR' },
        // { key: 'deINDEPENDANTCount', label: 'NbINDEP.' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchAzoliStatistics: "statistic/fetchAzoliStatistics",
      fetchEntreprises: "entreprise/fetchEntreprises",
      createOrUpdateEntreprise: "entreprise/createOrUpdateEntreprise",
      deleteEntreprise: "entreprise/deleteEntreprise",
      downloadAzoli: "entreprise/downloadAzoli",
      fetchDossier: "entreprise/fetchDossier",
      validerDossier: "entreprise/validerDossier",
      transfererVersConseiller: "transfertEntreprise/transfererVersConseiller",
      transfererVersAntenne: "transfertEntreprise/transfererVersAntenne",
    }),
    toggleDetails(de) {
      console.log(de);
    },
    showConseillerSelector() {
      this.$refs.conseillerSelector.show();
    },
    priseEnCharge(de) {
      this.$router.push({
        name: "espace.common.de.followup",
        params: { userRole: this.$route.params.userRole, entrepriseId: de.id },
      });
    },
    selectConseillerForTransfer(agent) {
      this.activeTransfert = {
        ...this.emptyTransferItem,
        agent,
      };
      this.showConseillerSelector();
    },
    computeProgression(priseEnCharge) {
      let percentage = 0;

      if (priseEnCharge.classement) percentage = 25;

      if (priseEnCharge.statutProfessionnel) percentage = 50;

      if (priseEnCharge.cheminEmploi) percentage = 75;

      if (priseEnCharge.consignes.length > 0) percentage = 90;

      return percentage;
    },
    transfertEntreprise(transfer) {
      console.log("Agent: ", transfer);
      let { agent, dossiers } = transfer;
      // if(this.selected.length == 0) return
      this.transfererVersConseiller({
        agent,
        // entreprise: this.selected[0],
        dossiers,
      }).then((data) => {
        console.log(data);
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });

      this.activeTransfert = null;
    },
    transfertEntrepriseVersAntenne(transfer) {
      let { antenne, dossiers } = transfer;
      this.transfererVersAntenne({
        antenne,
        dossiers,
      }).then((data) => {
        this.$toast.success("Opération réussie!", {
          position: "top-right",
          duration: 8000,
        });
      });

      this.activeTransfert = null;
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "agent",
        search: this.search,
        fetcherMethod: "agent/fetchAntenneAgentConseillerWithEntrepriseStatistics",
        dataGetter: "agent/agentWithStatistics",
        paginationGetter: "agent/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 25,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}`;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-chip {
  background: white !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #212529;
  cursor: pointer;
}
.custom-chip:hover {
  color: #212529;
  cursor: pointer;
}
.custom-chip.active {
  background: #007bff !important;
  color: white;
  font-weight: bold;
}
</style>
