<template>
  <div :class="borderClass" class="d-flex align-items-center bg-white rounded p-3">
    <!-- <div class="flex-shrink-0">
      <div class="bg-info style_level_offre d-flex flex-column justify-content-center align-items-center">
        <h3 class="my-0">
            <a href="#" class="text-white"><i class="bi-broadcast"></i></a>
        </h3>
      </div>
    </div> -->
    <div class="flex-grow-1 ms-3">
      <h6 class="my-0">{{ label }}</h6>
      <div class="row">
        <div class="col">
          <small>{{ subtitle }}</small>
          <h2 class="fw-bolder text-dark my-0">
            <ICountUp :delay="delay" :endVal="value" :options="options" />
          </h2>
        </div>
        <template v-if="isDual">
          <div class="col">
            <p-divider layout="vertical" />
          </div>
          <div class="col text-secondary">
            <small>{{ rightSubtitle }}</small>
            <h2 class="fw-bolder text- my-0">
              <ICountUp :delay="delay" :endVal="rightValue" :options="options" />
            </h2>
          </div>
        </template>
        <template v-if="showPercentage && !isDual">
          <div class="col">
            <p-divider layout="vertical" />
          </div>
          <div class="col text-secondary">
            <small>Proportion</small>
            <h4 class="fw-bolder text- my-0">
              <!-- <ICountUp :delay="delay2" :endVal="percentageValue" :options="options" /> -->
              {{ percentageValue }} %
            </h4>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";

export default {
  components: {
    ICountUp,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    borderClass: {
      type: String,
    },
    showPercentage: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Number,
      required: true,
    },
    percentage: {
      type: Number,
      default: 0,
    },
    percentageDivider: {
      type: Number,
      default: 0,
    },
    rightLabel: {
      type: String,
    },
    rightSubtitle: {
      type: String,
    },
    rightValue: {
      type: Number,
    },
  },
  data() {
    return {
      delay: 5000,
      delay2: 5000,
      options: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      options2: {
        useEasing: true,
        useGrouping: true,
        separator: "",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    };
  },
  computed: {
    isDual() {
      return this.rightSubtitle != null;
    },
    percentageValue() {
      return this.percentageDivider != 0
        ? parseFloat((this.value / this.percentageDivider) * 100).toFixed(2)
        : parseFloat(this.percentage).toFixed(2);
    },
  },
};
</script>
