<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 mt-5 mb-4">
        <div
          class="card-booking-completed pa-5 mb-5 d-flex flex-column justify-content-center"
          :height="550"
        >
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Identification
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Raison Sociale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.raisonSocial || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">IFU :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.ifu || "-" }}
                    </div>
                  </div>
                  <hr />
                  <!-- <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Forme juridique :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.formeJuridique.label || "-" }}
                    </div>
                  </div>
                  <hr /> -->
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">RCCM :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.rccm || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Adresse du siège :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.adresseSiege || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Commune :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.commune || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Contact
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.phone || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.whatsapp || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Site web :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.siteWeb || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.email || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Contact du dirigeant :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephoneDirigeant || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappDirigent || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                Personne à contacter
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Nom :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.nomContact || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Fonction :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.fonction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">E-mail :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.emailContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Téléphone :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.telephonePersonneContact || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Numéro whatsapp :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.numeroWhatsappPersonneContact || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                ACTIVITES DE L'ENTREPRISE
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activité pricipale :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.activites || "-" }}
                    </div>
                  </div>

                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Activités secondaires :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.annexes || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Produits et services :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.produitService || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Chiffre d'affaire :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.chiffreAffaire || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Cycle de production :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      {{ entreprise.cycleProduction || "-" }}
                    </div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-sm-5">
                      <h6 class="mb-0">Production en rotation :</h6>
                    </div>
                    <div class="col-sm-7 text-secondary">
                      <yes-or-no-badge :value="entreprise.productionRotation" />
                      {{ entreprise.productionRotation || "-" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                PERSONNEL EN FORMATION (PERSONNEL DE DIRECTION)
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
              <!-- <h6 class="mb-0">Effectif du personnel de direction</h6> -->
            </div>
            <div class="card-body">
              <div class="col">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif Total</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <!-- <h6 class="mb-0">Effectif Total</h6> -->
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span> {{ entreprise.effectifTotal || "-" }}</span>
                          </td>
                          <td>
                            <span>
                              {{ entreprise.effectifTotalHomme || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{ entreprise.effectifTotalFemme || "-" }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif permanent</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifPermanent || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif temporaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifTemporaire || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif stagiaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{ entreprise.effectifStagiaire || "-" }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireHomme || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireFemme || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                PERSONNEL EN FORMATION (PERSONNEL DE PRODUCTION)
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
              <!-- <h6 class="mb-0">Effectif du personnel de direction</h6> -->
            </div>
            <div class="card-body">
              <div class="col">
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif Total</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <!-- <h6 class="mb-0">Effectif Total</h6> -->
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTotalHommeProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTotalFemmeProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTotalFemmeProd || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif permanent</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentHommeProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifPermanentFemmeProd || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif temporaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireHommeProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifTemporaireFemmeProd || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Effectif stagiaire</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireHommeProd || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span>
                              {{
                                entreprise.effectifStagiaireFemmeProd || "-"
                              }}</span
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Formation des personnels :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.isFormationPersonnel" />
                  </div>
                </div>
                <hr />
                <div class="row" v-if="entreprise.isFormationPersonnel">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Interne</h6>
                  </div>
                  <div class="col-sm-3 text-secondary">
                    <yes-or-no-badge
                      :value="entreprise.isFormationPersonnelInterne"
                    />
                  </div>
                  <div class="col-sm-3">
                    <h6 class="mb-0">Externe</h6>
                  </div>
                  <div class="col-sm-3 text-secondary">
                    <yes-or-no-badge
                      :value="entreprise.isFormationPersonnelExterne"
                    />
                  </div>
                </div>

                <hr />
                <div class="row">
                  <div class="col-sm-5">
                    <h6 class="mb-0">Garderie personnel :</h6>
                  </div>
                  <div class="col-sm-7 text-secondary">
                    <yes-or-no-badge :value="entreprise.isGarderiePersonnel" />
                  </div>
                </div>
                <hr />
                <div class="row" v-if="entreprise.isGarderiePersonnel">
                  <div class="col-sm-3">
                    <h6 class="mb-0">Interne</h6>
                  </div>
                  <div class="col-sm-3 text-secondary">
                    <yes-or-no-badge
                      :value="entreprise.isGarderiePersonnelInterne"
                    />
                  </div>
                  <div class="col-sm-3">
                    <h6 class="mb-0">Externe</h6>
                  </div>
                  <div class="col-sm-3 text-secondary">
                    <yes-or-no-badge
                      :value="entreprise.isGarderiePersonnelExterne"
                    />
                  </div>
                </div>
                <hr />
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                AUDIT ENVIRONNEMENTAL ET SOCIAL
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12">
                    <div class="row">
                      <div class="col-sm-5">
                        <h6 class="mb-0">
                          Existe-t-il des toilettes ? (Photo si possible)
                        </h6>
                      </div>
                      <div class="col-sm-7 text-secondary">
                        <yes-or-no-badge :value="entreprise.isToilette" />
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="entreprise.isToilette">
                      <div class="col-sm-3">
                        <ImagePreview
                          :src="entreprise.imgToilette"
                          alt="Image toilette"
                          width="30"
                          preview
                        />
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-5">
                        <h6 class="mb-0">
                          L’eau potable est-elle disponible sur le site ? (Photo
                          si possible)
                        </h6>
                      </div>
                      <div class="col-sm-7 text-secondary">
                        <yes-or-no-badge :value="entreprise.isEauPotable" />
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="entreprise.isEauPotable">
                      <div class="col-sm-3">
                        <ImagePreview
                          :src="entreprise.imgEauPotable"
                          alt="Image eau potable "
                          width="30"
                          preview
                        />
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-5">
                        <h6 class="mb-0">
                          Existe-t-il un dispositif de lavage des mains ? (Photo
                          si possible)
                        </h6>
                      </div>
                      <div class="col-sm-7 text-secondary">
                        <yes-or-no-badge :value="entreprise.isLavageDeMain" />
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="entreprise.isLavageDeMain">
                      <div class="col-sm-3">
                        <ImagePreview
                          :src="entreprise.imgLavage"
                          alt="Image lavage de main "
                          width="30"
                          preview
                        />
                      </div>
                    </div>
                    <hr />
                    <div class="row">
                      <div class="col-sm-5">
                        <h6 class="mb-0">
                          Existe-t-il un dispositif de sécurité en cas
                          d’incendie ? (Photo si possible)
                        </h6>
                      </div>
                      <div class="col-sm-7 text-secondary">
                        <yes-or-no-badge :value="entreprise.isSecurite" />
                      </div>
                    </div>
                    <hr />
                    <div class="row" v-if="entreprise.isSecurite">
                      <div class="col-sm-3">
                        <ImagePreview
                          :src="entreprise.imgSecurite"
                          alt="Image de dispositif de securité "
                          width="30"
                          preview
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mb-3 border-1">
            <div class="card-header bg-white">
              <h5 class="card-title d-flex align-items-center">
                BESOIN EN PERSONNEL
                <span
                  ><b-btn size="xs" v-if="false" variant="outline-warning"
                    ><i class="bi bi-edit"></i></b-btn
                ></span>
              </h5>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12">
                  <div class="col-sm-12 text-secondary">
                    <table class="table table-borderless">
                      <thead class="border-bottom border-top">
                        <tr>
                          <th scope="col">Metier</th>
                          <th scope="col">Qualification</th>
                          <th scope="col">Total</th>
                          <th scope="col">Homme</th>
                          <th scope="col">Femme</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(besoin, index) in entreprise.besoinPersonnels"
                          :key="index"
                        >
                          <td>
                            <span> {{ besoin.metier.libelle || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ besoin.qualification || "-" }}</span>
                          </td>

                          <td>
                            <span>
                              {{
                                parseInt(besoin.femme) +
                                  parseInt(besoin.homme) || "-"
                              }}</span
                            >
                          </td>
                          <td>
                            <span> {{ besoin.homme || "-" }}</span>
                          </td>
                          <td>
                            <span> {{ besoin.femme || "-" }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      <div class="col-md-12">
        <div
          class="card-booking-completed pa-5 mb-5 mx-5 d-flex flex-column justify-content-center"
          :height="550"
        ></div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";
// import AzoliStatusBadge from "../../../components/common/AzoliStatusBadge.vue";
// import ProfilePhotoDragAndDrop from "../../../components/uploader/ProfilePhotoDragAndDrop.vue";
import { API_URL } from "../../../../../config/api-config";
// import QrcodeVue from "qrcode.vue";

export default {
  props: ["entrepriseId", "value"],
  components: {
    YesOrNoBadge,
  },
  data() {
    return {
      entreprise: this.value,
      API_URL,
    };
  },
  created() {
    // this.entreprise = localStorage.getItem('subscription') != undefined? JSON.parse(localStorage.getItem('subscription')) : this.entreprise
    // this.currentStep = localStorage.getItem('currentStep') != undefined? localStorage.getItem('currentStep') : this.currentStep
    if (this.entrepriseId) {
      this.fetchDossier({
        entrepriseId: this.entrepriseId,
      });
    }
  },
  watch: {
    // dossier(val) {
    //   if (val.azoli) {
    //     this.entreprise = {
    //       ...val,
    //       ...val.azoli,
    //     };
    //   }
    // },
    // value(val) {
    //   this.entreprise = {
    //     ...val,
    //   };
    // },
  },
  computed: {
    ...mapGetters({
      // entreprise: "entreprise/entreprise",
      dossier: "entreprise/entreprise",
      raisonEmploisMap: "setting/raisonEmploisMap",
      plageSalairesMap: "setting/plageSalairesMap",
      typeEmploisMap: "setting/typeEmploisMap",
    }),
    de() {
      return this.entreprise;
      // eslint-disable-next-line no-unreachable
      // console.log(this.entreprise);
    },
  },
  methods: {
    ...mapActions({
      fetchDossier: "entreprise/fetchDossier",
    }),
    fileChanged(e) {
      console.log(e);
      this.entreprise.urlPhoto = e.previewUrl;
    },
    profileUpdate(e) {
      console.log(e);
      this.entreprise = {
        ...this.entreprise,
        fullPhotoUrl: e.fullPhotoUrl,
      };
    },
  },
};
</script>

<style>
.card-title {
  color: #1a32c8 !important;
  /* color: #1A32C8 !important; */
  text-transform: uppercase;
}
hr {
  color: #bfa9a9;
  margin: 10px 5px;
}
</style>
