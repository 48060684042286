/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <p-confirmDialog></p-confirmDialog>
    <PSidebar
      class="p-sidebar-lg p-sidebar-close"
      :dismissable="true"
      :visible.sync="displayDialog"
      position="right"
      containerStyle="{width: '70vw'}"
    >
    </PSidebar>

    <!--<div class="statistic-box">
      <de-statistic @selectionChange="statisticBlockSelected" :dateDeb="dateDeb" :dateFin="dateFin"/>
    </div>-->

    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de début</label> -->
              <p-calendar
                placeholder="Date de début"
                v-model="dateDeb"
                locale="fr"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="field col-12 col-md-3">
              <!-- <label for="basic">Date de fin</label> -->
              <p-calendar
                placeholder="Date de fin"
                class="flex-grow-1"
                v-model="dateFin"
                :showIcon="true"
                :manualInput="false"
              />
            </div>
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par'"
                    />
                    <p-multi-select
                      v-model="mxSearchFields"
                      :options="searchFieldsOptions.filter((el) => el.visible)"
                      optionLabel="label"
                      optionValue="value"
                      :editable="true"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <template v-for="(option, index) in filterOptions">
                <div class="col-12 col-md-3 mb-4" v-if="option.visible" :key="index">
                  <label for="basic">{{ option.label }}</label>
                  <c-auto-complete
                    v-if="option.url"
                    display="chip"
                    v-model="option.value"
                    chips
                    :option="option"
                    :force-selection="true"
                  />
                  <p-multi-select
                    v-else-if="option.items && option.items.length != 0"
                    v-model="option.value"
                    :options="option.items"
                    :optionLabel="option.key"
                    :placeholder="option.label"
                    display="chip"
                  />
                  <p-input-text
                    v-else
                    v-model="option.value"
                    :placeholder="option.label"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des conventions</h4>
        </div>
        <SignatureEditorDialog ref="signatureEditor" @save="apposerSignature" />
        <div class="btn-group" v-if="$can('generate', 'Contrat')">
          <ConventionEditor
            ref="editor"
            :item="activeItem"
            @save="generateEntrepriseContrat"
          />
          <a @click.prevent="addContrat()" href="" class="style_btn btn btn-warning"
            >Générer une convention</a
          >
        </div>

        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-">
          <div class="bg-white p-3 border">
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="tableData"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>
                <template #head(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @change="selectAll"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                      :value="false"
                    />
                  </div>
                </template>
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template #cell(#)="data">
                  <div class="form-check form-check-inline">
                    <input
                      @input="selectionChanged($event, data)"
                      :checked="data.rowSelected"
                      class="form-check-input"
                      type="checkbox"
                      :id="`${data.index}`"
                      name="disponible"
                    />
                  </div>
                </template>
                <template #cell(nom)="{ item: contrat }">
                  <span class="text-uppercase">{{ contrat.nom }}</span>
                </template>

                <template #cell(dateSignature)="{ item: contrat }">
                  <span class="text-uppercase" v-if="contrat.dateSignature"
                    >{{ contrat.dateSignature | moment("DD-MM-YYYY") }}
                  </span>
                </template>
                <template #cell(dateFinContrat)="{ item: contrat }">
                  <span class="text-uppercase" v-if="contrat.dateFinContrat"
                    >{{ contrat.dateFinContrat | moment("DD-MM-YYYY") }}
                  </span>
                </template>
                <template #cell(status)="{ item: contrat }">
                  <PTag
                    class="text-uppercase badge badge-primary"
                    :severity="getStatusOffreColor(contrat.status)"
                    v-if="contrat.status"
                    >{{ contrat.status }}
                  </PTag>
                </template>

                <template #row-details="row">
                  <CandidatureList :extraQueryArg="`offreId=${row.item.id}`" />
                </template>

                <template #cell(actions)="row">
                  <div class="d-flex justify-content-end">
                    <!-- <button
                      class="btn btn-sm py-1 btn-outline-primary me-1"
                      @click="row.toggleDetails"
                    >
                      <i class="bi bi-journal-check"></i>
                    </button> -->
                    <a
                      class="btn btn-dark rounded-0 btn-sm me-1"
                      title="Télécharger le contrat"
                      @click.stop="imprimerEntrepriseContrat(row.item)"
                      ><i class="bi bi-file-arrow-down-fill"></i
                    ></a>
                    <a
                      class="btn btn-info rounded-0 btn-sm me-1"
                      title="Apposer signature"
                      v-if="row.item.status != 'ACTIF'"
                      @click.stop="showSignatureContratEditor(row.item)"
                      ><i class="bi bi-node-plus"></i
                    ></a>

                    <!-- <a class="btn btn-danger rounded-0 btn-sm me-1" title="Générer PDF" @click.stop.prevent="downloadFiche(contrat)"><i class="bi bi-printer"></i></a> -->
                    <!-- <a @click.stop.prevent="remplirFiche(contrat)" title="Saisir la fiche" class="btn btn-warning rounded-0 btn-sm me-1"><i class="bi bi-file-earmark-check"></i></a> -->
                    <!-- <button title="Valider la fiche" @click.prevent="validerFiche(contrat)" class="btn btn-light btn-sm">  <i :class="{ 'text-danger' : contrat.status == 'VALIDE'}" class="bi bi-caret-left-fill"></i></button> -->
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p>
                Affichage de l’élément
                {{ mxPagination.page * mxPagination.rowsPerPage }} à
                {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
                {{ mxTotalCount }} éléments
              </p>
              <b-pagination
                v-model="mxPagination.page"
                :total-rows="mxTotalCount"
                :per-page="mxPagination.rowsPerPage"
                aria-controls="my-table"
              ></b-pagination>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
// import DeStatistic from '../../../components/espace/demandeur/statistic/DeStatistic.vue'
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CandidatureList from "./CandidatureList.vue";
import SignatureEditorDialog from "../../../components/espace/common/signature/SignatureEditorDialog.vue";
import ConventionEditor from "../../../components/espace/entreprise/editor/ConventionEditor.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
    entrepriseId: {
      type: Number,
      default: null,
    },
  },
  components: {
    ConventionEditor,
    SignatureEditorDialog,
    CandidatureList,
    CAutoComplete,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      activeItem: {},
      contrat: [],
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },

        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      dossier: "demandeur/demandeur",
      statutDossiers: "setting/statutDossiers",
      // demandeurs: 'demandeur/demandeurs',
      candidatures: "candidature/candidatures",
      pagination: "demandeur/pagination",
      romes: "rome/romes",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "N°" },
        { key: "numeroContrat", label: "N° contrat" },
        // { key: "numeroActiviteProspection", label: "N° Activité prospection" },
        { key: "dateSignature", label: "Date de Signature" },
        { key: "dateFinContrat", label: "Date de fin" },
        { key: "typeContrat.libelle", label: "Type de contrat" },
        { key: "status", label: "Status" },

        // { key: 'validePar', label: 'Validé par' },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchContrat: "contrat/fetchContrat",
      generateContrat: "contrat/generateContrat",
      apposerSignatureContrat: "contrat/apposerSignatureContrat",
      telechargerContrat: "contrat/telechargerContrat",

      fetchRomes: "rome/fetchRomes",
      createOrUpdateBesoin: "besoin/createOrUpdateBesoin",
      fetchQualificationProfessionnelles:
        "qualificationProfessionnelle/fetchQualificationProfessionnelles",
    }),
    async contratTypeOne(value) {
      console.clear(this);
      console.log(value);
      await this.telechargerContrat({
        ...value,
      });
    },
    showSignatureContratEditor(convention) {
      if (convention.signatureUrl == null) {
        this.$refs.signatureEditor.show();
        this.activeItem = { ...convention };
      } else {
        this.$toast.info("Ce contrat a déjà été signé!", {
          position: "bottom-right",
          duration: 5000,
        });
      }
    },
    apposerSignature(signatureUrl) {
      console.log(signatureUrl);
      this.apposerSignatureContrat({
        urlSignature: signatureUrl,
        contratId: this.activeItem.id,
      }).then(() => {
        this.$toast.success("Contrat signé avec succès", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
    imprimerEntrepriseContrat(convention) {
      this.fetchContrat({ ...convention }).then((data) => {
        const contractEnterprise = data;
        if (contractEnterprise != undefined) {
          this.contratTypeOne(contractEnterprise);
        } else {
          this.$toast.error("Aucun contrat disponible", {
            position: "bottom-right",
            duration: 5000,
          });
        }
      });
    },
    generateEntrepriseContrat(convention) {
      this.generateContrat(convention);
    },
    getStatusOffreColor(status) {
      let color = "";
      if (status == "Pas disponible") {
        color = "danger";
      } else if (status == "ACTIF") {
        color = "success";
      } else if (status == "REVOQUEE") {
        color = "danger";
      } else if (status == "SUSPENDU") {
        color = "warning";
      }
      return color;
    },
    // priseEnCharge(de) {
    //   this.$router.push({
    //     name: "espace.common.de.followup",
    //     params: { userRole: this.$route.params.userRole, demandeurId: de.id },
    //   });
    // },
    // setDatePub(oneOffre) {
    //   this.activeOffre = { ...oneOffre };
    // },

    // transfertDemandeur(agent) {
    //   console.log("Agent: ", agent);
    //   if (this.selected.length == 0) {
    //     this.$toast.error(
    //       "Aucun demandeur sélectionné. Passez en mode tableau puis sélectionnez le(s) demandeur(s) avant de continuer. ",
    //       {
    //         position: "top-right",
    //         duration: 8000,
    //       }
    //     );
    //     return;
    //   }
    //   this.transfererVersConseiller({
    //     agent,
    //     demandeur: this.selected[0],
    //   }).then((data) => {
    //     console.log(data);
    //     this.$toast.success("Opération réussie!", {
    //       position: "top-right",
    //       duration: 8000,
    //     });
    //   });
    // },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "contrat",
        search: this.search,
        fetcherMethod: "contrat/fetchContrats",
        dataGetter: "contrat/contrats",
        paginationGetter: "contrat/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    // sformatToDataTable(data, { keyName }) {
    //   let summary = {};
    //   return Object.keys(data).map((k) => {
    //     let { key, M = 0, F = 0, total = 0 } = data[k];
    //     summary.M += M;
    //     summary.F += F;
    //     summary.total += total;
    //     if (data[k]["TOTAL"] == undefined) {
    //       return {
    //         [keyName]: key,
    //         M,
    //         F,
    //         total,
    //       };
    //     } else {
    //       return {
    //         [keyName]: "TOTAL",
    //         ...summary,
    //       };
    //     }
    //   });
    // },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    genererContrat(convention) {},
    addContrat() {
      this.activeItem = {
        entreprise: { id: this.entrepriseId },
        typeContrat: null,
      };
      this.$refs.editor.show();
    },
    updateContrat(payload) {
      // this.contrat.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },
    update(contrat) {
      this.activeItem = { ...contrat };
      this.$refs.editor.show();
    },
  },
};
</script>

<style></style>
