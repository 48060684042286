/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid bg-white">
    <p-confirmDialog></p-confirmDialog>
    <div class="container- mt-1">
      <p-progress-bar v-if="load" style="height: 0.4em" mode="indeterminate" />
      <p-divider />
      <template v-if="!load">
        <div class="bg-">
          <div>
            <h3>Evaluation</h3>
          </div>
          <hr />
          <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
            <div class="my-1" :class="getcollorCriticite">
              <!-- Criticité : <PTag :value=noteCriticite></PTag> -->
              <PTag class="badge" :class="getcollorCriticite">
                Criticité : {{ noteCriticite }}
              </PTag>
            </div>
            <div class="btn-group" :class="getcollorRisque">
              <PTag class="badge" :class="getcollorRisque">
                Risque : {{ noteRisque }}
              </PTag>
            </div>
          </div>

          <!-- <div class="bg-white p-3 border" style="pointer-events: none"> -->
          <!-- :class="{ 'inatif-table': evaluer == 'none' }" -->
          <div class="bg-white p-3 border">
            <!-- :class="{ 'pointer-events': evaluer }" -->
            <div class="table-responsive">
              <b-table
                show-empty
                empty-text="Aucune donnée à afficher"
                :fields="tableFields"
                class="align-middle"
                striped
                select-mode="multi"
                ref="table"
                selectable
                @row-selected="onRowSelected"
                :items="this.evaluationsConsat"
              >
                <template #head()="data">
                  <span>{{ data.label }} </span>
                </template>

                <template #cell(codeCouleur)="{ item: evaluation }">
                  <PBadge
                    size="large"
                    :class="evaluation.codeCouleur"
                    :value="evaluation.note"
                  >
                  </PBadge>
                </template>
                <template #cell(commentaire)="{ item }">
                  <ConfirmPopup
                    style="z-index: 10000"
                    :group="'_motif_editor_' + item.id"
                  >
                    <template #message>
                      <div class="card shadow-0 border-0 p-3 mb-2">
                        <h6>Commentaires/Conséquences</h6>
                        <textarea
                          class="form-control"
                          v-model="item.commentaire"
                          cols="40"
                          rows="4"
                        ></textarea>
                      </div>
                    </template>
                  </ConfirmPopup>
                  {{ item.commentaire | truncate(10) }}
                  <PButton
                    class="p-button-text p-button-sm"
                    v-tooltip.left="'Editer le contenu'"
                    icon="bi bi-pen"
                    @click.prevent.stop="
                      showModitifEditorPopup($event, '_motif_editor_' + item.id, item)
                    "
                  ></PButton>
                </template>
                <template #cell(Notation)="row">
                  <div class="d-flex justify-content-end">
                    <div class="col-md-12 col-md-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <div class="mb-3">
                          <PRating
                            v-model="row.item.note"
                            @change="calculCriticite(row.item)"
                            :stars="10"
                          />
                          <span
                            v-for="(err, index) in errors"
                            :key="index"
                            class="text-danger"
                            >{{ err }}</span
                          >
                        </div>
                      </ValidationProvider>
                    </div>
                  </div>
                </template>
                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  <span>{{ data.value }}</span>
                </template>
              </b-table>
            </div>
            <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
              <div class="my-1 flex-fill"></div>
              <div class="btn-group">
                <button
                  type="button"
                  @click.stop="validerEvaluation()"
                  class="style_btn btn btn-primary"
                >
                  Valider
                </button>
                <!-- :class="{ 'inatif-buton': evaluer == 'none' }" -->
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import FicheAzoliReport from "../../../components/report/FicheAzoliReport.vue";
import ReportHeader from "../../../components/report/ReportHeader.vue";
import ConstatEditorDialog from "../../../components/espace/entreprise/audit/editor/dialog/ConstatEditorDialog.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import CandidatureList from "./CandidatureList.vue";

import { VueEditor } from "vue2-editor";
import { FILE_UPLOAD_API_URL } from "../../../config/api-config";

import OffreDetailViewer from "../../portail/OffreDetailViewer.vue";
import ConfirmPopup from "primevue/confirmpopup";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: ``,
    },
    audit: {
      type: Object,
      required: false,
    },
    constat: {
      type: Object,
      required: false,
    },
  },
  components: {
    ConstatEditorDialog,
    FicheAzoliReport,
    ReportHeader,
    VueEditor,
    OffreDetailViewer,
    CandidatureList,
    CAutoComplete,
    ConfirmPopup,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      modal1: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      displayDialog: false,
      femmesAvecEnfantsCount: 0,
      nombreEnfants3Mois3AnsCount: 0,
      femmeEnceinteCount: 0,
      viewMode: localStorage.getItem("viewMode") || "table", //card, table
      parDepartementEnregistres: [],
      parDepartementFemmesEtEnfantsEntre3MoisEt3Ans: [],
      parCommuneEnregistres: [],
      parAeEnregistres: [],
      activeOffre: {},
      activeItem: {},
      activeConstat: {},
      evaluations: [],
      constat: [],
      // note: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }, { id: 8 }, { id: 9 }, { id: 10 }],
      note: "",
      load: false,
      notes: "",
      noteCriticite: this.constat.noteCriticite,
      noteRisque: this.constat.noteRisque,
      note_s: "",
      note_d: "",
      note_o: "",
      evaluer: this.constat.evaluer,

      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
          },
        },
      ],
      searchFields: ["nom"],
      searchFieldsOptions: [
        {
          label: "Bénéficiaire",
          value: "nom",
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          label: "Téléphone",
          value: "phone",
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          label: "Whatsapp",
          value: "whatsapp",
          visible: this.$can("filter.whatsapp", "Azoli"),
        },
        {
          label: "Commune",
          value: "commune",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          label: "Département",
          value: "departement",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          label: "AE (Nom & prénom)",
          value: "ae",
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          label: "Arrondissement",
          value: "arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          label: "Village/Quartier de ville",
          value: "ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          label: "Métier Appris",
          value: "metierAppris",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          label: "Métier Désiré",
          value: "metierDesire",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filterOptions: [
        {
          column: "nom",
          value: "",
          label: "Bénéficiaire",
          dropdown: false,
          visible: this.$can("filter.beneficiaire", "Azoli"),
        },
        {
          column: "phone",
          value: "",
          label: "Téléphone",
          dropdown: false,
          visible: this.$can("filter.telephone", "Azoli"),
        },
        {
          column: "creePar",
          value: "",
          label: "AE (Nom & prénom)",
          dropdown: false,
          visible: this.$can("filter.ae", "Azoli"),
        },
        {
          column: "departement",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Département",
          dropdown: true,
          url: "/departements",
          visible: this.$can("filter.departement", "Azoli"),
        },
        {
          column: "commune",
          key: "nom",
          valueKey: "nom",
          value: "",
          label: "Commune",
          dropdown: true,
          url: "/communes",
          visible: this.$can("filter.commune", "Azoli"),
        },
        {
          column: "arrondissement",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/arrondissements",
          label: "Arrondissement",
          visible: this.$can("filter.arrondissement", "Azoli"),
        },
        {
          column: "ville",
          key: "nom",
          valueKey: "nom",
          value: "",
          dropdown: true,
          url: "/villes",
          label: "Village/Quartier de ville",
          visible: this.$can("filter.ville", "Azoli"),
        },
        {
          column: "status",
          key: "label",
          value: "",
          label: "Statut du dossier",
          multiple: true,
          valueKey: "value",
          items: [
            { value: "NOUVEAU", label: "Sans dossier" },
            { value: "EN_COURS", label: "En cours" },
            { value: "TERMINE", label: "Terminé" },
            { value: "VALIDE", label: "Validé" },
          ],
          visible: this.$can("filter.status", "Azoli"),
        },
        {
          column: "metierAppris",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier appris",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierAppris", "Azoli"),
        },
        {
          column: "metierDesire",
          key: "libelle",
          valueKey: "libelle",
          value: "",
          label: "Metier recherché",
          dropdown: true,
          url: "/romes",
          visible: this.$can("filter.metierDesire", "Azoli"),
        },
      ],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,
        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeDemandeur: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Demandeurs",
          active: true,
        },
      ],
    };
  },
  created() {
    this.init();
  },
  watch: {
    dossier(val) {
      if (val.azoli) {
        this.activeDemandeur = {
          ...val,
          ...val.azoli,
        };
      }
    },
    constat(val) {
      if (val) {
        this.noteRisque = val.noteRisque;
        this.noteCriticite = val.noteCriticite;
        this.evaluer = val.estEvaluer;
        console.log(val);
      }
    },
    evaluer: {
      handler(val) {
        if (val.estEvaluer) {
          this.evaluer = "none";
        } else {
          this.evaluer = "";
        }
      },
      deep: true,
    },
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      constats: "constat/constats",
      criteres: "critere/criteres",
      evaluationsConsat: "evaluationConstat/evaluations",
      pagination: "demandeur/pagination",

      romes: "rome/romes",
    }),
    getcollorCriticite() {
      switch (true) {
        case 0 <= this.noteCriticite && this.noteCriticite <= 30:
          this.constat.codeCouleurCritique = "vert";
          return "vert"; //vert
        case 30 < this.noteCriticite && this.noteCriticite <= 40:
          this.constat.codeCouleurCritique = "jaune";
          return "jaune"; //jaune
        case 40 < this.noteCriticite && this.noteCriticite < 50:
          this.constat.codeCouleurCritique = "orange";
          return "orange"; // orange
        case 50 <= this.noteCriticite && this.noteCriticite <= 100:
          this.constat.codeCouleurCritique = "rouge";
          return "rouge"; // rouge
      }
      console.log(this.constat.codeCouleurCritique);
    },
    getcollorRisque() {
      switch (true) {
        case 0 <= this.noteRisque && this.noteRisque <= 300:
          this.constat.codeColeurRisque = "vert";
          return "vert"; //vert
        case 300 < this.noteRisque && this.noteRisque <= 400:
          this.constat.codeColeurRisque = "jaune";
          return "jaune"; //jaune
        case 400 < this.noteRisque && this.noteRisque < 500:
          this.constat.codeColeurRisque = "orange";
          return "orange"; // orange
        case 500 <= this.noteRisque && this.noteRisque <= 1000:
          this.constat.codeColeurRisque = "rouge";
          return "rouge"; // rouge
      }
      console.log(this.constat.codeColeurRisque);
    },

    tableFields() {
      return [
        // "#",
        // 'index',
        { key: "codeCouleur", label: "Couleur" },
        { key: "critere.libelle", label: "Criticité et risque" },
        "Notation",
        { key: "commentaire", label: "Commenaire" },
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
    fileApiUrl() {
      return FILE_UPLOAD_API_URL;
    },
  },
  methods: {
    ...mapActions({
      fetchConstats: "constat/fetchConstats",
      getAuditConstat: "constat/getAuditConstat",
      fetchCriteres: "critere/fetchCriteres",
      fetchActionRequisesByConstat: "actionrequise/fetchActionRequisesByConstat",
      createNewEvaluation: "evaluationConstat/createNewEvaluation",
      fetchEvaluationsByConstat: "evaluationConstat/fetchEvaluationsByConstat",
      createOrUpdateEvaluation: "evaluationConstat/createOrUpdateEvaluation",
    }),
    init() {
      this.load = true;
      console.log(this.constat);
      this.fetchEvaluationsByConstat(this.constat);
      this.fetchCriteres().then(() => {
        // this.fetchActionRequisesByConstat(this.constat),
        this.load = false;
      });
    },
    showModitifEditorPopup(event, popupGroupName, item) {
      this.$confirm.require({
        group: popupGroupName,
        target: event.currentTarget,
        // message: 'L\'activité a-t-elle pu tenir?',
        icon: "p",
        acceptLabel: "Enregistrer",
        rejectLabel: "Fermer",
        accept: () => {
          //   this.presenceChange(item);
          this.createOrUpdateEvaluation(item);
        },
        reject: () => {},
      });
    },
    validerEvaluation() {
      this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment enregistrer cette évaluation? Cette opération est irreversible`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          this.activeConstat.estEvaluer = true;
          this.$emit("updateNote", this.activeConstat);
        },
      });

      // }
    },
    calculCriticite(payload) {
      console.log(payload);
      if (payload.critere.code == "O") {
        this.note_o = payload.note;
      }
      if (payload.critere.code == "S") {
        this.note_s = payload.note;
      }
      if (payload.critere.code == "D") {
        this.note_d = payload.note;
      }
      this.noteCriticite = this.note_o * this.note_s;
      this.noteRisque = this.note_o * this.note_s * this.note_d;
      this.activeConstat = {
        ...this.constat,
        note_o: this.note_o,
        note_s: this.note_s,
        note_d: this.note_d,
        noteCriticite: this.noteCriticite,
        noteRisque: this.noteRisque,
        audit: this.audit,
      };
      payload = {
        ...payload,
        codeCouleur: this.getcollorVariable(payload.note),
      };
      this.createOrUpdateEvaluation(payload);

      // this.$emit("updateNote", this.activeConstat);
    },
    getcollorVariable(note) {
      switch (true) {
        case 0 <= note && note <= 5:
          return "vert"; //vert
        case 5 < note && note <= 5.5:
          return "jaune"; //jaune
        case 5.5 < note && note < 8:
          return "orange"; // orange
        case 8 <= note && note <= 10:
          return "rouge"; // rouge
      }
      console.log(note);
    },
    validation() {},
    saveConstat(payload) {
      payload = {
        ...payload,
        audit: this.audit,
      };
      console.log(payload);

      this.createOrUpdateConstat(payload)
        .then(() => {
          this.$toast.success("Enregistré ", {
            position: "bottom-right",
            duration: 8000,
          });
        })
        .catch((err) => {
          this.$toast.error("Echec ", {
            position: "bottom-right",
            duration: 8000,
          });
        });
    },
    getStatusOffreColor(status) {
      let color = "EN_ATTENTE";
      if (status == "VALIDE") {
        color = "primary";
      } else if (status == "PUBLIE") {
        color = "success";
      } else if (status == "ANNULE") {
        color = "danger";
      } else if (status == "EN_ATTENTE") {
        color = "secondary";
      }
      return color;
    },

    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "constat",
        search: this.search,
        fetcherMethod: "constat/fetchConstats",
        dataGetter: this.audit.constats,
        paginationGetter: "constat/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 20,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);
          return `dateDeb=${dateDeb}&dateFin=${dateFin}&${this.extraQueryArg}`;
        },
        searchFields: this.searchFields,
      });
    },
    getcollorCriticite1(payload) {
      switch (this.payload) {
        case 0 <= payload <= 30:
          return "#00561B"; //vert
        case 30 < payload <= 40:
          return "#FFFF00"; //jaune
        case 40 < payload < 50:
          return "#FAA401"; // orange
        case 50 <= payload <= 100:
          return "#FF0000"; // rouge
      }
    },

    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
    uploadFile(de) {
      console.log(de);
      this.$refs[`pond2`].click();
    },
    onProgress(e) {
      console.log(e);
    },
    addConstat() {
      // this.constat.push(emp)
      this.activeItem = {};
      this.$refs.editor.show();
    },
    updateConstat(payload) {
      // this.constat.push(emp)
      this.activeItem = { ...payload };
      this.$refs.editor.show();
    },

    update(constat) {
      this.activeItem = { ...constat };
      this.$refs.editor.show();
    },
  },
};
</script>

<style>
.vert {
  background: #00561b;
  color: white;
}

.jaune {
  background: #ffff00;
  color: black;
}

.orange {
  background: #faa401;
}

.rouge {
  background: #ff0000;
}
.inatif-table {
  pointer-events: none;
}
.inatif-buton {
  display: none;
}
</style>
